import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoginImg from "../assets/tutor/img.png";
import Img2 from "../assets/loginImages/google.png";
import Img3 from "../assets/loginImages/linkedin.avif";
import { useDispatch, useSelector } from "react-redux";
import { setTeacherLoginValues } from "../state/redux/teacherAuthSlice";
import { ToastContainer } from "react-toastify";
import { showToastError, showToastSuccess } from "../utils/toastify/toastify";
import validator from "validator";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Img from "../assets/Tutormore logo A (1).png";
const TeacherResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [resetPasswordValues, setResetPasswordValues] = useState({
    new_password: "",
    confirm_password: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetPasswordValues({ ...resetPasswordValues, [name]: value });
    const updatedValues = { ...resetPasswordValues, [name]: value };

    if (name === "new_password" || name === "confirm_password") {
      if (
        updatedValues.new_password &&
        updatedValues.confirm_password &&
        updatedValues.new_password !== updatedValues.confirm_password
      ) {
        setErrors({ ...errors, confirm_password: "Passwords do not match." });
      } else {
        const newErrors = { ...errors };
        delete newErrors.confirm_password;
        setErrors(newErrors);
      }

      if (
        name === "password" &&
        !validator.matches(
          value,
          /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
        )
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          new_password:
            "Password must be at least 8 characters long and contain at least one capital letter and one special character.",
        }));
      } else if (name === "new_password") {
        const newErrors = { ...errors };
        delete newErrors.new_password;
        setErrors(newErrors);
      }
    }
  };
  const handleSubmit = async (e) => {
    const reqBody = {
      newPassword: resetPasswordValues.new_password,
      confirm_password: resetPasswordValues.confirm_password,
    };
    console.log(reqBody);
    e.preventDefault();
    await axios({
      method: "POST",
      url: `https://tutor-backend.hitoritech.com/api/v1/teacher/student-new-password/${token}`,
      headers: {
        "Content-Type": "Application/json",
      },
      data: reqBody,
    })
      .then((response) => {
        console.log(response);
        showToastSuccess(response?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        showToastError(err?.response?.data?.toString());
      });
  };
  const [viewPassword, setViewPassword] = useState(false);
  const handleViewPassword = (index) => {
    setViewPassword(index === viewPassword ? null : index);
  };
  return (
    <div className="h-screen overflow-y-scroll hide-scrollbar  relative z-[1] flex flex-col gap-[30px] lg:flex-row lg:justify-between items-center p-[30px] sm:p-[50px] ">
      <ToastContainer />
      <div className=" w-full lg:w-[50%] flex flex-col gap-[20px] items-center justify-center">
        <div className="xl:w-[500px] xl:h-[200px]">
          <img src={Img} alt="loginImg" className="w-full h-full object-fill" />
        </div>
      </div>
      <div className="w-full lg:w-[50%]  flex justify-center items-center">
        <div className="rounded-[50px] shadow-md w-full sm:w-[70%] bg-[#FFFFFF4D] flex flex-col items-center justify-center p-[30px] sm:p-[50px]">
          <h2 className="text-[30px] font-bold text-center">
            Reset Your Password !
          </h2>
          <div className="flex flex-col my-2 gap-2 w-full items-center">
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="new_password">New Password</label>
              <div className="flex items-center gap-[7px]">
                <input
                  type={viewPassword === "new_password" ? "text" : "password"}
                  name="new_password"
                  id="new_password"
                  autoComplete="false"
                  value={resetPasswordValues.new_password}
                  onChange={handleChange}
                  className="bg-white w-full px-2 py-2 rounded-md"
                />
                {viewPassword === "new_password" ? (
                  <FaEyeSlash
                    className="cursor-pointer"
                    onClick={() => handleViewPassword("new_password")}
                  />
                ) : (
                  <FaEye
                    className="cursor-pointer"
                    onClick={() => handleViewPassword("new_password")}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="confirm_password">Confirm Password</label>
              <div className="flex items-center gap-[7px]">
                <input
                  type={
                    viewPassword === "confirm_password" ? "text" : "password"
                  }
                  name="confirm_password"
                  id="confirm_password"
                  autoComplete="false"
                  value={resetPasswordValues.confirm_password}
                  onChange={handleChange}
                  className="bg-white w-full px-2 py-2 rounded-md"
                />
                {viewPassword === "confirm_password" ? (
                  <FaEyeSlash
                    className="cursor-pointer"
                    onClick={() => handleViewPassword("confirm_password")}
                  />
                ) : (
                  <FaEye
                    className="cursor-pointer"
                    onClick={() => handleViewPassword("confirm_password")}
                  />
                )}
              </div>
            </div>
            {errors.new_password && (
              <p className="text-red-700 font-medium text-center text-[14px]">
                {errors.new_password}
              </p>
            )}
            {errors.confirm_password && (
              <p className="text-red-700 font-medium text-center text-[14px]">
                {errors.confirm_password}
              </p>
            )}
            <button
              onClick={handleSubmit}
              className="bg-[#f25019] text-white w-[90%] py-2 my-2 rounded-md font-semibold"
            >
              Submit
            </button>
            <div className="w-[90%] text-center text-[15px] mt-2 flex flex-col items-center justify-between">
              <Link to="/teacher/login">
                <span className="font-semibold"> Back To Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherResetPassword;
