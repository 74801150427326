import React, { useEffect, useState } from "react";
import Layout from "./layout/layout";
import { FaPencil, FaSearchengin, FaTrash } from "react-icons/fa6";
import axios from "axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const Teacher = () => {
  const [search, setSearch] = useState("");
  const [teachers, setTeachers] = useState([]);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const token = localStorage.getItem("adminToken");
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://tutor-backend.hitoritech.com/api/v1/admin/fetch-all-teachers",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res?.data?.teachers);
        setTeachers(res?.data?.teachers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[90%] p-[25px] h-[90%] bg-white rounded-[12px] overflow-y-scroll hide-scrollbar">
          <div className="w-full flex flex-col xl:flex-row gap-[10px] xl:items-center justify-between">
            <div className="flex flex-col gap-[7px]">
              <h1 className="font-semibold text-[15px]">All Teachers</h1>
            </div>
            <div className="flex items-center p-[7px] bg-[#f9fbff] rounded-[50px] gap-[7px]">
              <input
                type="text"
                name="search"
                id="search"
                className=" bg-transparent outline-none border-0 p-0"
                onChange={handleChange}
                value={search}
                autoComplete="false"
              />
              <FaSearchengin />
            </div>
          </div>
          <table className="w-full whitespace-nowrap">
            <tbody>
              <tr
                tabIndex="0"
                className="focus:outline-none h-16 border border-gray-100 rounded"
              >
                <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      TEACHER ID
                    </p>
                  </div>
                </td>
                <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      NAME
                    </p>
                  </div>
                </td>
                <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      EMAIL
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      CREATED AT
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      VIEW CHATS
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      VIEW PROBLEMS
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      VIEW PROFILE
                    </p>
                  </div>
                </td>
                {/* <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      ACTIONS
                    </p>
                  </div>
                </td> */}
              </tr>
              {teachers.map((item, index) => {
                return (
                  <tr
                    key={index}
                    tabIndex="0"
                    className="focus:outline-none min-h-10 h-10 border border-gray-100 rounded"
                  >
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.id}
                        </p>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.name}
                        </p>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.email}
                        </p>
                      </div>
                    </td>
                    <td className="pl-5">
                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                          {moment(item?.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/admin/teacher-chats/${item?.id}`)
                      }
                      className="pl-5"
                    >
                      <div className="flex items-center">
                        <button className="focus:ring-2 focus:ring-offset-2 text-sm leading-none text-white font-medium py-3 px-5 bg-[#f59c36] rounded focus:outline-none">
                          View
                        </button>
                      </div>
                    </td>

                    <td
                      onClick={() => {
                        navigate(`/admin/teacher-problems/${item?.id}`);
                      }}
                      className="pl-5"
                    >
                      <div className="flex items-center">
                        <button className="focus:ring-2 focus:ring-offset-2 text-sm leading-none text-white font-medium py-3 px-5 bg-[#f59c36] rounded focus:outline-none">
                          View
                        </button>
                      </div>
                    </td>

                    <td className="pl-5">
                      <div className="flex items-center">
                        <button className="focus:ring-2 focus:ring-offset-2 text-sm leading-none text-white font-medium py-3 px-5 bg-[#f59c36] rounded focus:outline-none">
                          View
                        </button>
                      </div>
                    </td>

                    {/* <td className="pl-5">
                      <div className="flex gap-[35px] items-center">
                        <FaTrash />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Teacher;
