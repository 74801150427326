import React from "react";
import Header from "../common/Header";
import Img1 from "../assets/schedule/img1.png";
import { Link } from "react-router-dom";

const Schedule = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="relative w-full xl:h-[300px] h-[500px]">
        <img
          src={Img1}
          alt="Group Session"
          className="w-full h-full object-cover"
        />
        <div className="w-full absolute inset-0 bg-[#F2951E] bg-opacity-75 flex items-center justify-center">
          <div className="text-white w-full text-xl font-bold flex flex-col gap-[7px] items-center ">
            <h1 className="text-center w-full font-semibold font-proxima text-4xl leading-10 mb-4">
              More updates will be provided
            </h1>
            <p className="w-full text-center font-medium leading-[1.3em] text-[14px]">
              for more information and queries regarding online and physical
              lessons (1-to-1 and group tuition), you may reach us at:
            </p>

            <Link
              className="w-full flex justify-center text-[14px]"
              to="mailto:officialtutormore@gmail.com"
            >
              <button className="w-full">
                officialtutormore@gmail.com or Whatsapp/Call us at 89382114
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
