import React, { useEffect } from "react";
import Layout from "./layout/layout";
import Img7 from "../assets/about/img8.png";
import PricingCard from "../dashboard/components/PricingCard";
import { setStudentButtons } from "../state/sidebarSlice";
import { useDispatch } from "react-redux";

const StudentPricing = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStudentButtons("pricing"));
  }, []);
  return (
    <Layout>
      <div className="w-full flex justify-center items-center  xl:h-[90vh] p-[25px] xl:px-[30px]">
        <div className="w-full flex flex-col gap-[20px]  xl:flex-row justify-center items-center  ">
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[550px] ">
            <PricingCard type="LEARNER" cost="29" />
          </div>
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[550px] ">
            <PricingCard type="STUDENT" cost="38" />
          </div>
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[550px]">
            <PricingCard type="APPRENTICE" cost="56" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StudentPricing;
