import React, { useState } from "react";
import Layout from "./layout/layout";
import Img from "../assets/student/img2.png";
import { FaNoteSticky, FaPlus } from "react-icons/fa6";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStudentButtons } from "../state/sidebarSlice";
const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStudentButtons("dashboard"));
  }, []);
  const token = localStorage.getItem("studentToken");
  const [notifications, setNotifications] = useState(null);
  const navigate = useNavigate();
  const getNottfications = async () => {
    await axios({
      url: "https://tutor-backend.hitoritech.com/api/v1/student/notification",
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setNotifications(res.data.Data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNottfications();
  }, []);
  const loadStudent = useSelector((state) => state?.loadStudentSlice?.user);

  return (
    <Layout>
      <div className="h-screen text-[12px] overflow-y-scroll hide-scrollbar flex items-center justify-center">
        <div className="w-[90%] items-center h-[90%] overflow-y-scroll hide-scrollbar bg-white rounded-[12px] p-[25px] flex flex-col gap-[20px]">
          <div className="w-full p-[30px] rounded-[12px] bg-[#f9c88e] flex flex-col sm:flex-row justify-between">
            <div className="w-full sm:w-[50%] flex flex-col gap-[12px]">
              <h3 className="font-semibold ">
                {moment(loadStudent?.updatedAt).format("YYYY-MM-DD")}
              </h3>
              <h1 className="font-bold text-[22px]">
                Welcome back, {loadStudent?.name || "User"} !
              </h1>
              <p className="font-medium">
                Always stay updated in your student portal
              </p>
            </div>
            <div className="w-full sm:w-[40%] h-[150px]">
              <img src={Img} alt="img" className="w-full h-full object-cover" />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <h2 className="font-semibold text-[20px]">Courses</h2>
            <Link to="/student/ask-question">
              <button className="px-[20px] bg-[#f59c36] text-white rounded-[7px] font-semibold py-[7px] flex items-center gap-[7px]">
                <FaPlus />
                Ask Question
              </button>
            </Link>
          </div>
          <div className="w-full flex flex-col xl:flex-row gap-[20px]">
            <div className="w-full xl:w-[75%] flex flex-wrap justify-center gap-[20px]">
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  1st
                  <br />
                  Class
                </h2>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  2nd
                  <br />
                  Class
                </h2>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  3rd
                  <br />
                  Class
                </h2>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  4th
                  <br />
                  Class
                </h2>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  5th
                  <br />
                  Class
                </h2>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[30%] rounded-[7px] h-[150px] bg-[#fce1c3] flex flex-col items-center justify-center">
                <h2 className="leading-[1.3em] text-center font-bold text-[22px]">
                  6th
                  <br />
                  Class
                </h2>
              </div>
            </div>
            <div className="w-full xl:w-[25%] bg-[#fff1f1] rounded-[7px] text-[12px] flex flex-col gap-[12px] p-[20px]">
              <h3 className="font-semibold text-[17px]">Notifications</h3>
              {notifications?.map((item, index) => {
                return (
                  <div key={index} className="flex gap-[12px] items-center">
                    <FaNoteSticky />
                    <div className="flex flex-col gap-[4px]">
                      <Link to={item.text}>
                        <h4 className="leading-[1.3em]">{item.text}</h4>
                      </Link>
                      <p className="text-gray-500">
                        {moment(item.created_at).fromNow()}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
