import React, { useState } from "react";
import Layout from "./layout/layout";
import Img from "../assets/admin/img.png";
import {
  FaCamera,
  FaCheck,
  FaKey,
  FaPlus,
  FaUser,
  FaWrench,
} from "react-icons/fa6";
import Img1 from "../assets/admin/img1.png";
import { FaEdit } from "react-icons/fa";
const Account = () => {
  const [profilePageValues, setProfilePageValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    country: "",
  });
  const [skills, setSkills] = useState("");
  const [skillsData, setSkillsData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfilePageValues({ ...profilePageValues, [name]: value });
  };

  const handleSkillsChange = (e) => {
    setSkills(e.target.value);
  };

  const handleAddItem = () => {
    if (editIndex !== -1) {
      return;
    }

    const trimmedSkills = skills.trim();
    if (trimmedSkills) {
      const newData = [...skillsData, trimmedSkills];
      setSkillsData(newData);
      setSkills("");
    }
  };

  const handleEditItem = (index) => {
    const item = skillsData[index];
    const data = item;
    setSkills(data);
    setEditIndex(index);
  };

  const handleUpdateItem = () => {
    if (editIndex === -1) {
      return;
    }
    const updateData = [...skillsData];
    updateData[editIndex] = skills;
    setSkillsData(updateData);
    setEditIndex(-1);
    setSkills("");
  };

  const [img, setImg] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const blobUrl = URL.createObjectURL(file);
    setImg({
      file: file,
      url: blobUrl,
    });
  };

  return (
    <Layout>
      <div className="w-full xl:h-screen py-[20px] flex flex-col  items-center justify-center">
        <div className="p-[15px] w-full ">
          <h1 className="font-bold text-[20px]">Admin Profile Page</h1>
        </div>
        <div className="w-[90%] h-[90%] p-[25px] bg-[#f8f9fb] overflow-y-scroll hide-scrollbar rounded-[12px] flex flex-col xl:flex-row gap-[20px] justify-center">
          <div className="w-full xl:w-[30%] bg-white  flex flex-col items-center ">
            <div className="flex flex-col p-[25px] gap-[7px]">
              <div className="flex relative items-center gap-[7px]">
                {img !== null ? (
                  <img
                    src={img.url}
                    alt="image"
                    className="w-[120px] h-[120px] rounded-[70px] "
                  />
                ) : (
                  <img
                    src={Img}
                    alt="profile"
                    className="w-[120px] h-[120px] rounded-[70px] "
                  />
                )}
                <div className="absolute right-0 top-0 w-full h-full">
                  <label htmlFor="img">
                    <FaCamera />
                  </label>
                  <input
                    type="file"
                    name="img"
                    id="img"
                    onChange={handleImageChange}
                    className="opacity-0 h-full w-full"
                  />
                </div>
              </div>
              <h2 className="font-semibold">Duran Clayton</h2>
            </div>
            <div className="border-t text-[12px] flex flex-col gap-[7px] border-gray-400 p-[25px]">
              <div className="flex cursor-pointer items-center gap-[12px]">
                <FaUser />
                Edit Profile
              </div>
              <div className="flex cursor-pointer items-center gap-[12px]">
                <FaWrench />
                Settings
              </div>
              <div className="flex cursor-pointer items-center gap-[12px]">
                <FaKey />
                Change Password
              </div>
            </div>
          </div>
          <div className="w-full xl:w-[70%] flex flex-col gap-[12px]">
            <div className="w-full h-[150px] flex">
              <img
                src={Img1}
                alt="image"
                className="w-full h-full object-fill"
              />
            </div>
            <div className="w-full overflow-y-scroll hide-scrollbar h-full bg-white rounded-[12px] flex flex-col gap-[0px]">
              <div className="w-full p-[20px] text-[14px] flex flex-col gap-[7px]">
                <h2 className="font-semibold text-[17px]">Edit Profile</h2>
                <p>Set Up Your Personal Information</p>
              </div>
              <div className="p-[20px] text-[12px] border-t border-gray-400 flex flex-wrap gap-[20px] justify-center">
                <div className="w-full sm:w-[48.4%] flex flex-col gap-[7px]">
                  <label className="font-semibold" htmlFor="firstName">
                    First Name :
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="p-[7px] text-[12px] outline-none border border-gray-400"
                    onChange={handleChange}
                    value={profilePageValues.firstName}
                    placeholder="Duran"
                    autoComplete="false"
                  />
                </div>
                <div className="w-full sm:w-[48.4%] flex flex-col gap-[7px]">
                  <label className="font-semibold" htmlFor="lastName">
                    Last Name :
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    value={profilePageValues.lastName}
                    id="lastName"
                    className="p-[7px] text-[12px] outline-none border border-gray-400"
                    placeholder="Clayton"
                    autoComplete="false"
                  />
                </div>
                <div className="w-full sm:w-[48.4%] flex flex-col gap-[7px]">
                  <label className="font-semibold" htmlFor="phoneNumber">
                    Phone Number :
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="p-[7px] text-[12px] outline-none border border-gray-400"
                    onChange={handleChange}
                    value={profilePageValues.phoneNumber}
                    placeholder="+91 1234 567 8910"
                    autoComplete="false"
                  />
                </div>
                <div className="w-full sm:w-[48.4%] flex flex-col gap-[7px]">
                  <label className="font-semibold" htmlFor="country">
                    Country :
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    className="p-[7px] text-[12px] outline-none border border-gray-400"
                    onChange={handleChange}
                    value={profilePageValues.country}
                    placeholder="India"
                    autoComplete="false"
                  />
                </div>

                <div className="w-full flex flex-col gap-[7px]">
                  <label className="font-semibold" htmlFor="skills">
                    Languages :
                  </label>
                  <div className="flex items-center gap-[7px]">
                    <input
                      type="text"
                      name="skills"
                      id="skills"
                      onChange={handleSkillsChange}
                      value={skills}
                      className="w-full p-[7px] text-[12px] outline-none border border-gray-400"
                      autoComplete="false"
                    />

                    {editIndex !== -1 ? (
                      <button className="cursor-pointer">
                        <FaCheck onClick={() => handleUpdateItem()} />
                      </button>
                    ) : (
                      <button onClick={handleAddItem}>
                        <FaPlus />
                      </button>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-wrap items-center gap-[7px]">
                  {skillsData?.map((item, index) => {
                    return (
                      <div className="flex items-center gap-[7px]">
                        <p>{item}</p>
                        <button>
                          <FaEdit onClick={() => handleEditItem(index)} />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="w-full text-[14px] justify-center flex gap-[20px] items-center">
                <button className="bg-[#f59c36] text-white px-[20px] py-[7px] rounded-[7px]">
                  Update Profile
                </button>
                <button className="bg-[#f8f9fb] text-[#8d8d8d] px-[20px] py-[7px] rounded-[7px]">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
