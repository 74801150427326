import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: null,
  socket: null,
};

const loadStudentSlice = createSlice({
  name: "loadStudentSlice",
  initialState,
  reducers: {
    setStudentSlice: (state, action) => {
      state.user = action.payload;
    },
    setSocketStudent: (state, action) => {
      state.socket = action.payload;
    },
  },
});

export const { setStudentSlice, setSocketStudent } = loadStudentSlice.actions;

export default loadStudentSlice.reducer;
