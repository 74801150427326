import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  studentButtons: "",
  teacherButtons: "",
  adminButtons: "",
};

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setStudentButtons: (state, action) => {
      state.studentButtons = action.payload;
    },
    setTeacherButtons: (state, action) => {
      state.teacherButtons = action.payload;
    },
    setAdminButtons: (state, action) => {
      state.adminButtons = action.payload;
    },
  },
});

export const { setStudentButtons, setTeacherButtons, setAdminButtons } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
