import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  adminLoginValues: {
    email: "",
    password: "",
  },
  adminRegisterValues: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  },
};

const adminAuthSlice = createSlice({
  name: "adminAuthSlice",
  initialState,
  reducers: {
    setAdminLoginValues: (state, action) => {
      state.adminLoginValues = action.payload;
    },
    setAdminRegisterValues: (state, action) => {
      state.adminRegisterValues = action.payload;
    },
  },
});

export const { setAdminLoginValues, setAdminRegisterValues } =
  adminAuthSlice.actions;

export default adminAuthSlice.reducer;
