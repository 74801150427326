import React from "react";
import { Navigate } from "react-router-dom";

const StudentProtectedRoute = ({ children }) => {
  if (localStorage.getItem("studentToken")) {
    return children;
  } else {
    return <Navigate to={"/student/login"} />;
  }
};

export default StudentProtectedRoute;
