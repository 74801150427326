import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper/bundle";
import "./slider.css";
import Img from "../../assets/testimonial/1.png";
import Img1 from "../../assets/profile1.jpg";
import Img2 from "../../assets/testimonial/3.png";
import Img3 from "../../assets/testimonial/4.png";
import MaleStudent from "../../assets/Screenshot 2024-06-30 at 3.20.08 PM.png";
import FemaleStudent from "../../assets/femaleAvt.png";
const Slider = () => {
  const swiperContainerRef = useRef(null);
  const swiperInstanceRef = useRef(null);

  useEffect(() => {
    if (swiperContainerRef.current) {
      swiperInstanceRef.current = new Swiper(swiperContainerRef.current, {
        loop: true,
        slidesPerView: 1.2,
        grabCursor: true,
        breakpoints: {
          500: {
            slidesPerView: 1.1,
          },
          780: {
            slidesPerView: 1.4,
          },
          1300: {
            slidesPerView: 2.6,
          },
          1630: {
            slidesPerView: 3.2,
          },
        },
      });

      // Cleanup on unmount
      return () => {
        if (swiperInstanceRef.current) {
          swiperInstanceRef.current.destroy(true, true);
        }
      };
    }
  }, []);

  const handlePrevClick = () => {
    if (swiperInstanceRef.current) {
      swiperInstanceRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperInstanceRef.current) {
      swiperInstanceRef.current.slideNext();
    }
  };

  const arr = [0, 1, 2, 3, 4, 5];
  const [testimonial, setTestimonial] = useState([
    {
      img: Img2,
      name: "Mrs. Vee Said :",
      message1:
        "This (customized learning) will be helpful for Reayaa because she has dance CCA twice per week and usually does her homework at night... most of the time I am at work and can't really help her much with her studies",
      text1: "After one month with Tutormore",
      name2: "Reayaa Said :",
      message2:
        "Mr Daniel is a very kind and patient teacher, whenever I am not sure about how to do questions he always make sure that I understand it really well before doing the next question..",
    },
    {
      img: Img3,
      name: "Nimisha",
      message1:
        "Mr. Daniel taught me math and science for P6. He taught both subjects in a clear way that allowed me to understand easily. When I thought that certain questions were silly, he still helped me with them and explained the questions patiently. When I needed explanations many times, he would explain them to me as many times as it took to make me understand. He was also very patient and kind throughout the classes. He was also very accommodating when it came to changing class timings.",
    },
    {
      img: MaleStudent,
      name: "Javier Wong",
      message1:
        "Mr Daniel taught me English in 2022. he is an amazing teacher. He teaches very simple yet effective methods to counter all my problems from essays to comprehension passages. Although he concentrated mostly on composition writings, i'm glad to say it really boosted my grade. He is very lenient with the deadline of the homework given. If I need any help during secondary school, Mr Daniel is my go-to teacher!",
    },
    {
      img: Img,
      name: "Mr Chan Said :",
      message1:
        "We decided to stop previous tuition classes because Luke was still asking me for help with his work despite having the tuition sessions… which means I have to pay for the classes and still teach him on my own…",
      text1: "After one month with Tutormore",
      name2: "Luke Said :",
      message2:
        "Teacher Daniel had been very patient and helpful with my questions. Helping answer my questions very quickly and to achieve good results for my latest class test.",
    },
    {
      img: MaleStudent,
      name: "Jaeden Tan",
      message1:
        "Mr Daniel is a kind hearted and caring teacher he’s there for me when I need him and he does not give up on me he will motivate me and it helped me to achieve a good path in life. The online consultation is very useful as it allows me to ask my math and science questions when I need help, and the teachers are available to answer my questions with proper guidance and solution.",
    },


    {
      img: FemaleStudent,
      name: "Ms Nini Said :",
      message1:
        "I think convenience is very important because he has many extra curriculum activities and will be tired after his soccer CCA... So I prefer 1-on-1 Sessions for him... I believe it is more effective for his learning",
      text1: "After one month with Tutormore",
      name2: "Safir Said :",
      message2:
        "The lessons we had so far helped me a lot in my English and Math. The lessons were done professionally and you were also caring and wanted to help me even more.",
    },







  ]);
  return (
    <div className="section-testmonials flex-col flex xl:flex-row">
      <div className="column-testmonials w-full">
        <div className="section-details">
          <h4 className="name-section">Students</h4>
          <h2 className="title-section">Testimonials</h2>
        </div>

        <div className="navigation-testmonials">
          <div
            className="swiper-button-testmonials-prev"
            onClick={handlePrevClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="65"
              viewBox="0 0 64 65"
              fill="none"
            >
              <circle
                cx="32"
                cy="32"
                r="31"
                transform="matrix(-1 0 0 1 64 0.947266)"
                stroke="#f59c36"
                strokeWidth="2"
              />
              <path
                d="M34.5967 20.9197C35.1642 20.3521 36.0843 20.3521 36.6519 20.9197C37.2194 21.4872 37.2194 22.4073 36.6519 22.9749L34.5967 20.9197ZM36.6519 22.9749L24.3207 35.306L22.2655 33.2509L34.5967 20.9197L36.6519 22.9749Z"
                fill="#f59c36"
              />
              <path
                d="M34.9724 45.9749C35.5399 46.5424 36.4601 46.5424 37.0276 45.9749C37.5951 45.4073 37.5951 44.4872 37.0276 43.9197L34.9724 45.9749ZM37.0276 43.9197L24.6964 31.5885L22.6412 33.6437L34.9724 45.9749L37.0276 43.9197Z"
                fill="#f59c36"
              />
            </svg>
          </div>
          <div
            className="swiper-button-testmonials-next"
            onClick={handleNextClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="65"
              viewBox="0 0 64 65"
              fill="none"
            >
              <circle cx="32" cy="32.9473" r="32" fill="#f59c36" />
              <path
                d="M29.4033 20.9197C28.8358 20.3521 27.9157 20.3521 27.3481 20.9197C26.7806 21.4872 26.7806 22.4073 27.3481 22.9749L29.4033 20.9197ZM27.3481 22.9749L39.6793 35.306L41.7345 33.2509L29.4033 20.9197L27.3481 22.9749Z"
                fill="white"
              />
              <path
                d="M29.0276 45.9749C28.4601 46.5424 27.5399 46.5424 26.9724 45.9749C26.4049 45.4073 26.4049 44.4872 26.9724 43.9197L29.0276 45.9749ZM26.9724 43.9197L39.3036 31.5885L41.3588 33.6437L29.0276 45.9749L26.9724 43.9197Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="swiper-testmonials" ref={swiperContainerRef}>
        <div className="swiper-wrapper">
          {testimonial.map((item, index) => {
            return (
              <div
                style={{ backgroundColor: "#f59c36" }}
                key={index}
                className="swiper-slide"
              >
                <div className="card-slide">
                  <div className="head-slide">
                    <div className="header-slide">
                      <div className="w-[150px] h-[150px] rounded-[150px]">
                        <img
                          src={item?.img}
                          alt="img"
                          className="w-full h-full object-fill rounded-[150px]"
                        />
                      </div>
                      <div className="title-slide">
                        <h4>{item?.name}</h4>
                      </div>
                    </div>
                  </div>
                  <p className="text-slide">{item?.message1}</p>
                  <h3 className="text-white font-semibold">{item?.text1}</h3>
                  <h3 className="text-white font-semibold">{item?.name2}</h3>
                  <p className="text-white">{item?.message2}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Slider;
