import React, { useState } from "react";
import Layout from "./layout/layout";
import {
  FaChartLine,
  FaNoteSticky,
  FaPencil,
  FaTrash,
  FaUpload,
} from "react-icons/fa6";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import { useDispatch } from "react-redux";
import { setStudentButtons } from "../state/sidebarSlice";

const StudentDashbaord = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStudentButtons("problemAsks"));
  }, []);
  const [notifications, setNotifications] = useState([
    {
      message: "You have a bug that needs to...",
      time: "Just Now",
    },
    {
      message: "New User Registered!",
      time: "59 minutes ago",
    },
    {
      message: "Andi Lane Subscribed to you",
      time: "Today, 11 : 59 AM",
    },
  ]);

  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const token = localStorage.getItem("studentToken");
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  console.log(selectedData);
  const getDashboard = async () => [
    await axios({
      url: "https://tutor-backend.hitoritech.com/api/v1/student/dashbaord",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setData(res.data.Data);
        console.log(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      }),
  ];
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <Layout>
      <div className="relative w-full py-[20px] sm:py-[0px] sm:h-screen text-[14px] overflow-y-scroll hide-scrollbar flex items-center justify-center">
        <div className="w-[95%] xl:h-[90%] xl:overflow-y-scroll hide-scrollbar bg-white rounded-[12px] flex flex-col xl:flex-row ">
          <div className="w-full xl:w-[100%] flex flex-col p-[15px] gap-[15px]">
            <div className="border-b border-gray-400 p-[15px] flex gap-[12px] justify-center">
              <p className="text-gray-500">Dashboards </p>
            </div>
            <div className="flex p-[10px] text-[12px] justify-center flex-wrap w-full gap-[20px] items-center">
              <div className="w-full sm:w-[45%] xl:w-[31%] flex flex-col gap-[7px] p-[25px] rounded-[12px] bg-[#e3f5ff]">
                <h3 className="font-semibold">Total Questions</h3>
                <div className="flex justify-between items-center">
                  <h2 className="text-[20px] font-semibold">
                    {data?.studentProblems?.length}
                  </h2>
                  <div className="flex gap-[7px] items-center">
                    {/* <p>+11.01%</p>
                    <FaChartLine /> */}
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[31%] flex flex-col gap-[7px] p-[25px] rounded-[12px] bg-[#f7f9fb]">
                <h3 className="font-semibold">Total Chats</h3>
                <div className="flex justify-between items-center">
                  <h2 className="text-[20px] font-semibold">
                    {data?.chatCount}
                  </h2>
                  <div className="flex gap-[7px] items-center">
                    {/* <p>-0.03%</p>
                    <FaChartLine /> */}
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[45%] xl:w-[31%] flex flex-col gap-[7px] p-[25px] rounded-[12px] bg-[#f7f9fb]">
                <h3 className="font-semibold">Completed</h3>
                <div className="flex justify-between items-center">
                  <h2 className="text-[20px] font-semibold">
                    {data?.completedProblem}
                  </h2>
                  <div className="flex gap-[7px] items-center">
                    {/* <p>+15.03%</p>
                    <FaChartLine /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full overflow-y-scroll hide-scrollbar">
              <table className="w-full whitespace-nowrap">
                <tbody>
                  <tr
                    tabIndex="0"
                    className="focus:outline-none h-16 border bg-[#f8f9fb] border-gray-100 rounded"
                  >
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          Text
                        </p>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          Craeted At
                        </p>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          current Status
                        </p>
                      </div>
                    </td>
                    <td className="pl-5">
                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                          img
                        </p>
                      </div>
                    </td>

                    <td className="pl-5">
                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                          view Question
                        </p>
                      </div>
                    </td>
                  </tr>
                  {data?.studentProblems?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        tabIndex="0"
                        className="focus:outline-none min-h-10 h-10 border border-gray-100 rounded"
                      >
                        <td className="">
                          <div className="flex items-center pl-5">
                            <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                              {item.text}
                            </p>
                          </div>
                        </td>
                        <td className="">
                          <div className="flex items-center pl-5">
                            <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                              {moment(item.createdAt).fromNow()}
                            </p>
                          </div>
                        </td>
                        <td className="">
                          <div className="flex items-center pl-5">
                            <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                              {item.status}
                            </p>
                          </div>
                        </td>
                        <td className="pl-5">
                          <div className="flex items-center">
                            <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                              <img
                                className="w-[40px] h-[40px]"
                                src={`https://tutor-backend.hitoritech.com/${item.attachment_url}`}
                                alt=""
                              />
                            </p>
                          </div>
                        </td>

                        <td className="pl-5">
                          <div className="flex gap-[35px] mb-2 items-center justify-center text-center">
                            <button
                              onClick={() => {
                                handleModal();
                                setSelectedData(item);
                              }}
                              className="px-[20px] py-[10px] bg-[#f59c37] rounded-[10px] font-semibold text-white"
                            >
                              view
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {modal === true ? (
          <div className="w-full h-full absolute bg-black top-0 left-0 p-[25px] bg-opacity-[.6] flex flex-col items-center justify-center">
            <div className="w-full  mx-auto bg-white shadow-lg rounded-md overflow-hidden sm:w-[50%]   ">
              <div class="md:flex w-full flex flex-col gap-[12px]">
                <div class="w-full   flex flex-col  justify-between">
                  <div className="w-full p-[20px]  flex flex-col gap-[7px]">
                    <div class="flex  justify-between items-center "></div>
                    {selectedData?.text ? (
                      <div className="flex flex-col gap-[4px]">
                        <p className="font-medium">Problem : </p>
                        <span className="font-normal ">
                          {selectedData?.text}
                        </span>
                      </div>
                    ) : null}
                    <p className="font-semibold">
                      Created {moment(selectedData?.createdAt).fromNow()}
                    </p>
                    {selectedData?.status ? (
                      <p className="font-medium">
                        Status :{" "}
                        <span className="font-normal">
                          {selectedData?.status}
                        </span>
                      </p>
                    ) : null}
                  </div>

                  {selectedData?.attachment_url ? (
                    <div>
                      <Zoom>
                        <img
                          src={`https://tutor-backend.hitoritech.com/${selectedData?.attachment_url}`}
                          class="w-full h-75"
                          className="w-full h-[250px] object-contain"
                        />
                      </Zoom>
                    </div>
                  ) : null}
                  <div className="p-[20px]">
                    <button
                      className="w-[100%] bg-[#F59C36] py-[10px] text-[white] font-medium rounded-[10px]"
                      onClick={() => {
                        handleModal();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default StudentDashbaord;
