import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  baseUrl,
  getRequest,
  postRequest,
} from "../../../../utils/apiServices";
export const fetchStudentChats = createAsyncThunk(
  "message/fetchStudentChats",
  async ({ studentId }) => {
    try {
      const response = await getRequest(
        `${baseUrl}/get-student-chat/${studentId}`
      );
      console.log(response);

      return response.data.chat;
    } catch (error) {
      return error;
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "message/fetchMessages",

  async ({ chatid }) => {
    try {
      const response = await getRequest(
        `${baseUrl}/student/get-messages/${chatid}`,
        localStorage.getItem("studentAuthToken")
      );
      return response.data.data;
    } catch (error) {
      return error;
    }
  }
);

export const sendTextMessage = createAsyncThunk(
  "message/sendTextMessage",
  async ({ chatid, senderid, textMessage, attachment_url, attachment }) => {
    try {
      const response = await postRequest(
        `${baseUrl}/student/new-message`,
        {
          chatid: chatid,
          text: textMessage,
          senderid: senderid,
          attachment_url: attachment_url,
          attachment: attachment,
        },
        localStorage.getItem("studentAuthToken")
      );
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const createChat = createAsyncThunk(
  "message/createChat",
  async ({ studentId, teacherId }) => {
    try {
      const response = await postRequest(`${baseUrl}/new-chat`, {
        studentId,
        teacherId,
      });

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const fetchTeacherData = createAsyncThunk(
  "message/fetchTeacherData",
  async () => {
    try {
      const response = await getRequest(
        `${baseUrl}/get-teacher`,
        localStorage.getItem("teacherAuthToken")
      );
      return response.data.teacher;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchStudentData = createAsyncThunk(
  "message/fetchStudentData",
  async () => {
    try {
      const response = await getRequest(
        `${baseUrl}/get-student`,
        localStorage.getItem("studentAuthToken")
      );
      return response.data.client;
    } catch (error) {
      console.error(error);
    }
  }
);
