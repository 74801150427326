import { configureStore } from "@reduxjs/toolkit";
import studentAuthSlice from "./redux/studentAuthSlice";
import teacherAuthSlice from "./redux/teacherAuthSlice";
import studentMessageSlice from "./redux/studentMessageRedux/studentMessageSlice";
import teacherMessageSlice from "./redux/teacherMessageRedux/teacherMessageSlice";
import loadTeacherSlice from "./redux/loadTeacher";
import loadStudentSlice from "./redux/loadStudent";
import adminAuthSlice from "./redux/adminAuthSlice";
import adminStudentMessageSlice from "./redux/adminReduxSlice/adminStudentMessageSlice";
import adminTeacherMessageSlice from "./redux/adminReduxSlice/adminTeacherMessageSlice";
import sidebarSlice from "./sidebarSlice";

const rootReducer = {
  studentAuthSlice: studentAuthSlice,
  teacherAuthSlice: teacherAuthSlice,
  studentMessageSlice: studentMessageSlice,
  teacherMessageSlice: teacherMessageSlice,
  loadTeacherSlice: loadTeacherSlice,
  loadStudentSlice: loadStudentSlice,
  adminAuthSlice: adminAuthSlice,
  adminStudentMessageSlice: adminStudentMessageSlice,
  adminTeacherMessageSlice: adminTeacherMessageSlice,
  sidebarSlice: sidebarSlice,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
