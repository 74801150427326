import React, { useRef } from "react";
import Header from "../../common/Header";
import ScrollToTopButton from "../../common/scrollToUpBtn";

const HomeLayout = ({ children }) => {
  const scrollContainerRef = useRef(null);

  return (
    <div className="h-full scroll-container relative flex flex-col bg-white">
      <Header />
      <div
        className="w-full flex overflow-y-scroll hide-scrollbar"
        ref={scrollContainerRef}
      >
        <div className="w-full ">{children}</div>
      </div>
      <ScrollToTopButton scrollContainerRef={scrollContainerRef} />
    </div>
  );
};

export default HomeLayout;
