import React from "react";
import Sidebar from "../elements/sidebar";
import Header from "../elements/header";
import { motion } from "framer-motion";

const Layout = ({ children }) => {
  const divVar = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div className="w-full h-screen flex relative">
      <div className="w-[20%] h-full overflow-y-scroll hide-scrollbar hidden xl:block bg-white  bg-primaryColor text-black">
        <Sidebar />
      </div>
      <div className="w-full xl:w-[80%] h-screen flex flex-col  ">
        <Header />

        <motion.div
          variants={divVar}
          initial="hidden"
          animate="visible"
          className="w-full h-screen flex overflow-y-scroll hide-scrollbar"
        >
          <div className="w-full h-full ">{children}</div>
        </motion.div>
      </div>
    </div>
  );
};

export default Layout;
