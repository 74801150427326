import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginImg from "../assets/tutor/img.png";
import Img2 from "../assets/loginImages/google.png";
import Img3 from "../assets/loginImages/linkedin.avif";
import { useDispatch, useSelector } from "react-redux";
import { setTeacherLoginValues } from "../state/redux/teacherAuthSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Img from "../assets/Tutormore logo A (1).png";
import { ToastContainer } from "react-toastify";
import { showToastError } from "../utils/toastify/toastify";
const TeacherLogin = () => {
  const navigate = useNavigate();
  const teacherLoginValues = useSelector(
    (state) => state.teacherAuthSlice.teacherLoginValues
  );
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setTeacherLoginValues({ ...teacherLoginValues, [name]: value }));
  };
  const handleSubmit = async (e) => {
    const reqBody = {
      email: teacherLoginValues.email,
      password: teacherLoginValues.password,
    };
    console.log(reqBody);
    e.preventDefault();
    await axios({
      method: "POST",
      url: "https://tutor-backend.hitoritech.com/api/v1/teacher/teacher-login",
      headers: {
        "Content-Type": "Application/json",
      },
      data: reqBody,
    })
      .then((response) => {
        localStorage.setItem("teacherToken", response.data.token);
        navigate("/teacher/");
      })
      .catch((err) => {
        console.log(err);
        showToastError(err?.response?.data?.message?.toString());
      });
  };
  const [viewPassword, setViewPassword] = useState(false);
  const handleViewPassword = (index) => {
    setViewPassword(index === viewPassword ? null : index);
  };
  return (
    <div className="h-screen overflow-y-scroll hide-scrollbar  relative z-[1] flex flex-col gap-[30px] lg:flex-row lg:justify-between items-center p-[30px] sm:p-[50px] ">
      <ToastContainer />
      <div className=" w-full lg:w-[50%] flex flex-col gap-[20px] items-center justify-center">
        <div className="xl:w-[500px] xl:h-[200px]">
          <img src={Img} alt="loginImg" className="w-full h-full object-fill" />
        </div>
      </div>
      <div className="w-full lg:w-[50%]  flex justify-center items-center">
        <div className="rounded-[50px] shadow-md w-full sm:w-[70%] bg-[#FFFFFF4D] flex flex-col items-center justify-center p-[30px] sm:p-[50px]">
          <h2 className="text-[30px] font-bold text-center">
            Login for Teacher
          </h2>
          <div className="flex flex-col my-2 gap-2 w-full items-center">
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="false"
                value={teacherLoginValues.email}
                onChange={handleChange}
                className="bg-white w-full px-2 py-2 rounded-md"
              />
            </div>

            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="password">Password</label>
              <div className="flex gap-[7px] items-center">
                <input
                  type={viewPassword === "password" ? "text" : "password"}
                  name="password"
                  id="password"
                  autoComplete="false"
                  value={teacherLoginValues.password}
                  onChange={handleChange}
                  className="bg-white w-full px-2 py-2 rounded-md"
                />
                {viewPassword === "password" ? (
                  <FaEyeSlash
                    onClick={() => handleViewPassword("password")}
                    className="cursor-pointer bg-white p-[7px] rounded-[7px] text-[30px]"
                  />
                ) : (
                  <FaEye
                    onClick={() => handleViewPassword("password")}
                    className="cursor-pointer bg-white p-[7px] rounded-[7px] text-[30px]"
                  />
                )}
              </div>
            </div>
            <Link to="/teacher/forgot-password" className="w-[90%]">
              <h3 className="w-full sm:w-[90%]">Forgot Password?</h3>
            </Link>
            <button
              onClick={handleSubmit}
              className="bg-[#f25019] text-white w-[90%] py-2 my-2 rounded-md font-semibold"
            >
              Login
            </button>

            <div className="w-[90%] text-center text-[15px] mt-2 flex flex-col items-center justify-between">
              <p>
                Don't have an Account Yet?
                <Link to="/teacher/register">
                  <span className="font-semibold"> Register For Free</span>
                </Link>
              </p>
              <p className="font-semibold mt-5 text-center text-[15px]">
                Try to be Login as Student ?{" "}
                <Link to="/student/login" className="text-blue-700">
                  Log In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherLogin;
