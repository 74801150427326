import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "./layout/layout";
import { Audio } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { setTeacherButtons } from "../state/sidebarSlice";

const ProblemFeed = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTeacherButtons("problemFeed"));
  }, []);
  const [probelms, setProblems] = useState();
  const [student, setStudent] = useState(null);
  const [close, setClose] = useState(false);
  const socket = useSelector((state) => state.loadTeacherSlice.socket);
  const [newProblem, setNewProblem] = useState(null);
  const navigate = useNavigate();
  const Token = localStorage.getItem("teacherToken");

  const getStudentByid = async (id) => {
    await axios({
      url: `https://tutor-backend.hitoritech.com/api/v1/teacher/get-student-by-id/${id}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + Token,
      },
    })
      .then((res) => {
        setStudent(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWaitingProblem = async () => {
    await axios({
      url: "https://tutor-backend.hitoritech.com/api/v1/teacher/get-waiting-problem",
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        setProblems(res.data.Data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChatNow = async (id) => {
    await axios({
      url: `https://tutor-backend.hitoritech.com/api/v1/teacher/alloted-teacher/${id}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        navigate("/teacher/chat");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getWaitingProblem();
  }, []);

  const instantAlert = async (problem) => {
    console.log(problem);
    if (socket === null) return;
    socket.emit("teacherAlloted", { problem });
  };
  const handleNewProbelm = (res) => {
    setNewProblem(res.problem);
    console.log(res);
  };

  useEffect(() => {
    if (newProblem === null) return;
    setProblems((prevProblems) => [newProblem, ...prevProblems]);
  }, [newProblem]);

  useEffect(() => {
    if (socket === null) return;
    socket.on("teacherNewProblem", handleNewProbelm);
  }, [socket]);

  return (
    <Layout>
      <div className="flex  relative">
        <div className=" p-[25px] w-full xl:w-[70%] h-full flex flex-col gap-[20px] overflow-y-scroll hide-scrollbar">
          {probelms?.map((item, index) => (
            <div
              key={index}
              className="w-full  mx-auto bg-white shadow-lg rounded-md overflow-hidden sm:w-[70%] mt-[30px] "
            >
              <div class="md:flex w-full flex flex-col gap-[12px]">
                <div class="w-full   flex flex-col justify-between">
                  <div className="w-full p-[15px]  flex flex-col gap-[7px]">
                    <div class="flex  justify-between items-center ">
                      <div class="flex flex-row items-center">
                        <img
                          src={`https://tutor-backend.hitoritech.com/${item?.studentDetaiils?.photo}`}
                          class="rounded-full object-cover"
                          width="40"
                        />

                        <div class="flex flex-row items-center ml-2">
                          <span
                            class="font-bold mr-1 cursor-pointer"
                            onClick={() => {
                              setClose(true);
                              getStudentByid(item?.studentDetaiils.id);
                            }}
                          >
                            {item?.studentDetaiils?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="font-semibold">
                      Posted {moment(item?.createdAt).fromNow()}
                    </p>
                    <p>{item.text}</p>
                  </div>

                  {item?.attachment_url ? (
                    <div>
                      <Zoom>
                        <img
                          src={`https://tutor-backend.hitoritech.com/${item?.attachment_url}`}
                          class="w-full h-75"
                          className="w-full h-[250px] object-contain"
                        />
                      </Zoom>
                    </div>
                  ) : null}
                  <div class="p-4 flex justify-between items-center gap-[10px]">
                    <button
                      className="w-[100%] bg-[#F59C36] py-[10px] text-[white] font-medium rounded-[10px]"
                      onClick={() => {
                        instantAlert(item);
                        ChatNow(item.id);
                      }}
                    >
                      Chat Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {close === true ? (
          <div className="bg-white w-full sm:w-[70%] xl:w-[30%] gap-8 h-full hide-scrollbar overflow-y-scroll fixed pb-[100px]  right-0 p-[20px] cursor-pointer ">
            <button
              onClick={() => {
                setClose(false);
              }}
            >
              {" "}
              Close
            </button>
            <p className="text-[25px] font-bold w-[100%]">
              About {student?.name}
            </p>
            {student?.launguage ? (
              <div className="flex relative w-full justify-between mt-[20px]">
                <p className="w-50% text-gray-500">Language</p>
                <p className="w-50%">English</p>
              </div>
            ) : null}
            <div>
              <p className="text-[25px] font-bold w-[100%] mt-[30px]">
                Activity
              </p>
              <p className="text-[13px]">Past few question asked</p>
              <p className="w-[100%] p-[10px] text-[white]"></p>
              {student?.problem.map((item) => (
                <div
                  className={`flex border-2 bg-[#F59C36] ${
                    item.attachment_url ? "rounded-[20px]" : "rounded-[10px]"
                  } mx-[10px] mt-[10px]`}
                >
                  {item.attachment_url ? (
                    <img
                      className="w-[30%] rounded-[17px]"
                      src={`https://tutor-backend.hitoritech.com/${item.attachment_url}`}
                      alt=""
                    />
                  ) : null}
                  <div className="p-[12px]">
                    <p className="m-0 text-white font-bold">Question:</p>
                    <p className="w-[100%]  text-[white]">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default ProblemFeed;
