import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import StudentLogin from "./studentPages/login";
import StudentRegister from "./studentPages/register";
import TeacherLogin from "./teacherPages/login";
import TeacherRegister from "./teacherPages/register";
import { WebcamCapture } from "./studentPages/camPage";
import Home from "./dashboard/Home";
import Pricing from "./dashboard/Pricing";
import About from "./dashboard/About";
import Schedule from "./dashboard/Schedule";
import TeacherChat from "./chatpages/TeacherChat";
import StudentChat from "./chatpages/StudentChat";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSocket, setTeacherSlice } from "./state/redux/loadTeacher";
import { setSocketStudent, setStudentSlice } from "./state/redux/loadStudent";
import Students from "./admin-panel/students";
import Teachers from "./admin-panel/teachers";
import Account from "./admin-panel/account";
import Login from "./admin-panel/login";
import Register from "./admin-panel/register";
import TutorDashboard from "./teacherPages/dashboard";
import TutorAccount from "./teacherPages/account";
import Dashboard from "./studentPages/dashboard";
import StudentAccount from "./studentPages/account";
import StudentPricing from "./studentPages/pricing";
import AskQuestion from "./studentPages/AskQuestion";
import ProblemFeed from "./teacherPages/ProblemFeed";
import { io } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewStudentChats from "./admin-panel/studentChats";
import StudentProblem from "./admin-panel/studentProblems";
import TeacherProblem from "./admin-panel/teacherProblems";
import ViewTeacherChats from "./admin-panel/teacherChats";
import PageNotFound from "./studentPages/pageNotFound";
import TeacherPageNotFound from "./teacherPages/pageNotFound";
import AdminPageNotFound from "./admin-panel/pageNotFound";
import PageNotFound404 from "./dashboard/pageNotFound";
import StudentDashbaord from "./studentPages/StudentDashbaord";
import StudentProtectedRoute from "./utils/protectedRoutes/studentProtectedRoute";
import TeacherProtectedRoute from "./utils/protectedRoutes/teacherProtectedRoute";
import AdminProtectedRoute from "./utils/protectedRoutes/adminProtectedRoute";
import StudentVerifyEmailPage from "./studentPages/verify-email";
import TeacherVerifyEmailPage from "./teacherPages/verify-email";
import AdminVerifyEmailPage from "./admin-panel/verify-email";
import StudentForgotPassword from "./studentPages/forgot-password";
import StudentResetPassword from "./studentPages/reset-password";
import TeacherForgotPassword from "./teacherPages/forgot-password";
import TeacherResetPassword from "./teacherPages/reset-password";
function App() {
  const location = useLocation();
  const teacherToken = localStorage.getItem("teacherToken");
  const studentToken = localStorage.getItem("studentToken");
  const loadStudentValue = useSelector((state) => state.loadStudentSlice.user);
  const loadTeachervalue = useSelector((state) => state.loadTeacherSlice.user);
  const navigate = useNavigate();

  const socket = useSelector((state) => state.loadStudentSlice.socket);
  const socketTeacher = useSelector((state) => state.loadTeacherSlice.socket);
  const dispatch = useDispatch();
  const loadTeacher = async () => {
    if (location.pathname.startsWith("/teacher/register")) return;
    if (location.pathname.startsWith("/teacher/forgot-password")) return;

    await axios({
      url: "https://tutor-backend.hitoritech.com/api/v1/teacher/loaduser",
      headers: {
        Authorization: `Bearer ${teacherToken}`,
      },
    })
      .then((res) => {
        console.log(res, "loaduser teacher");
        dispatch(setTeacherSlice(res.data.user));
      })
      .catch((err) => {
        console.log(err);
        navigate("/teacher/login");
        localStorage.removeItem("teacherToken");
      });
  };

  const loadStudent = async () => {
    if (location.pathname.startsWith("/student/register")) return;
    if (location.pathname.startsWith("/student/forgot-password")) return;

    await axios({
      url: "https://tutor-backend.hitoritech.com/api/v1/student/loaduser",
      headers: {
        Authorization: `Bearer ${studentToken}`,
      },
    })
      .then((res) => {
        console.log(res, "load student");
        dispatch(setStudentSlice(res.data.user));
      })
      .catch((err) => {
        console.log(err);
        navigate("/student/login");
        localStorage.removeItem("studentToken");
      });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/student")) {
      // if (socket !== null) return;
      console.log("The current URL changed to:", location.pathname);
      const newSocket = io("https://tutor-backend.hitoritech.com/", {
        timeout: 60000,
        pingInterval: 25000,
      });
      newSocket.on("connect", () => {
        console.log("Connected to the server");
      });
      newSocket.on("connect_error", (error) => {
        console.error("Connection error:", error);
      });
      dispatch(setSocketStudent(newSocket));
      return () => {
        newSocket.disconnect();
      };
    }
  }, [loadStudentValue]);
  useEffect(() => {
    if (location.pathname.startsWith("/student")) {
      if (!location.pathname.startsWith("/student/login")) {
        if (socket === null) return;
        socket.emit("newStudent", loadStudentValue?.id);
      }
    }
  }, [socket]);
  useEffect(() => {
    if (location.pathname.startsWith("/teacher")) {
      // if (socket !== null) return;
      console.log("The current URL changed to:", location.pathname);
      const newSocket = io("https://tutor-backend.hitoritech.com");
      newSocket.on("connect", () => {
        console.log("Connected to the server");
      });
      newSocket.on("connect_error", (error) => {
        console.error("Connection error:", error);
      });
      dispatch(setSocket(newSocket));
      return () => {
        newSocket.disconnect();
      };
    }
  }, [loadTeachervalue]);

  useEffect(() => {
    if (location.pathname.startsWith("/teacher")) {
      if (!location.pathname.startsWith("/teacher/login")) {
        if (socketTeacher === null) return;
        socketTeacher.emit("newTeacher", loadTeachervalue?.id);
      }
    }
  }, [socketTeacher]);

  useEffect(() => {
    console.log("The current URL changed to:", location.pathname);
    if (location.pathname.startsWith("/teacher")) {
      if (location.pathname.startsWith("/teacher/register")) return;

      loadTeacher();
      return;
    }
    if (location.pathname.startsWith("/student")) {
      if (location.pathname.startsWith("/student/register")) return;
      loadStudent();
      return;
    }
  }, [location.pathname]);

  const notify = (item) => toast(item);

  useEffect(() => {
    console.log("notification");
    if (location.pathname.startsWith("/student")) {
      console.log("notification");
      if (socket === null) return;
      const handleNewMessage = (res) => {
        notify("Your Teacher is alloted");
        console.log(res, "notification");
      };
      console.log("resTeacherAlloted");
      socket.on("resTeacherAlloted", handleNewMessage);
      return () => {
        socket.off("resTeacherAlloted", handleNewMessage);
      };
    }
  }, [socket]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/*" element={<PageNotFound404 />} />
        <Route path="/student">
          <Route path="login" element={<StudentLogin />} />
          <Route path="forgot-password" element={<StudentForgotPassword />} />
          <Route
            path="reset-password/:token"
            element={<StudentResetPassword />}
          />
          <Route path="register" element={<StudentRegister />} />
          <Route
            path="verify-email/:email/:token"
            element={<StudentVerifyEmailPage />}
          />
          <Route
            path="cam"
            element={
              <StudentProtectedRoute>
                <WebcamCapture />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="chat"
            element={
              <StudentProtectedRoute>
                <StudentChat />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <StudentProtectedRoute>
                <Dashboard />
              </StudentProtectedRoute>
            }
          />
          <Route
            path=""
            element={
              <StudentProtectedRoute>
                <Dashboard />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="problems"
            element={
              <StudentProtectedRoute>
                <StudentDashbaord />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="account"
            element={
              <StudentProtectedRoute>
                <StudentAccount />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="pricing"
            element={
              <StudentProtectedRoute>
                <StudentPricing />
              </StudentProtectedRoute>
            }
          />
          <Route
            path="ask-question"
            element={
              <StudentProtectedRoute>
                <AskQuestion />
              </StudentProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/teacher">
          <Route path="login" element={<TeacherLogin />} />
          <Route path="register" element={<TeacherRegister />} />
          <Route path="forgot-password" element={<TeacherForgotPassword />} />
          <Route
            path="reset-password/:token"
            element={<TeacherResetPassword />}
          />
          <Route
            path="verify-email/:email/:token"
            element={<TeacherVerifyEmailPage />}
          />
          <Route
            path="chat"
            element={
              <TeacherProtectedRoute>
                <TeacherChat />
              </TeacherProtectedRoute>
            }
          />
          <Route
            path=""
            element={
              <TeacherProtectedRoute>
                <TutorDashboard />
              </TeacherProtectedRoute>
            }
          />
          <Route
            path="account"
            element={
              <TeacherProtectedRoute>
                <TutorAccount />
              </TeacherProtectedRoute>
            }
          />
          <Route
            path="problem-feed"
            element={
              <TeacherProtectedRoute>
                <ProblemFeed />
              </TeacherProtectedRoute>
            }
          />
          <Route path="*" element={<TeacherPageNotFound />} />
        </Route>
        <Route path="/admin">
          <Route
            path="students"
            element={
              <AdminProtectedRoute>
                <Students />
              </AdminProtectedRoute>
            }
          />
          <Route
            path=""
            element={
              <AdminProtectedRoute>
                <Students />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="teachers"
            element={
              <AdminProtectedRoute>
                <Teachers />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="account"
            element={
              <AdminProtectedRoute>
                <Account />
              </AdminProtectedRoute>
            }
          />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route
            path="student-chats/:id"
            element={
              <AdminProtectedRoute>
                <ViewStudentChats />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="teacher-chats/:id"
            element={
              <AdminProtectedRoute>
                <ViewTeacherChats />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="student-problems/:id"
            element={
              <AdminProtectedRoute>
                <StudentProblem />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="teacher-problems/:id"
            element={
              <AdminProtectedRoute>
                <TeacherProblem />
              </AdminProtectedRoute>
            }
          />
          <Route path="*" element={<AdminPageNotFound />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
