import React, { useState } from "react";
import Footer from "../common/Footer";
import Img1 from "../assets/about/img2.png";
import Img7 from "../assets/about/img8.png";
import Img8 from "../assets/about/img9.png";
import Img9 from "../assets/about/img10.png";
import Logo from "../assets/header/Tutormore logo A.png";
import Teacher1 from "../assets/teachers/1.jpeg";
import Teacher2 from "../assets/teachers/2.jpeg";
import Teacher3 from "../assets/teachers/3.jpeg";
import { FaCheck } from "react-icons/fa6";
import { motion } from "framer-motion";
import HomeLayout from "./layout/HomeLayout";
import { Link, useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(null);
  const handleTabs = (index) => {
    setTabs(index === tabs ? null : index);
  };
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <HomeLayout>
      <div className="w-full">
        <motion.div
          variants={Animation}
          initial="hidden"
          whileInView={"visible"}
          className="w-full h-full flex flex-col gap-[20px] p-[30px] xl:p-[50px] items-center bg-white"
        >
          <h4 className="text-[17px]">ABOUT COMPANY</h4>

          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full flex flex-col xl:flex-row gap-[30px] items-center justify-center"
          >
            <div className="w-full xl:w-[50%]">
              <img src={Logo} alt="img" className="w-[80%]" />
            </div>
            <div className="w-full xl:w-[50%] flex flex-col gap-[12px]">
              <p>
                Tutormore is a web platform and application providing 1-on-1
                academic help to students. Students can simply chat and consult
                our tutors or snap a photo of their question from relevant
                subjects, and our tutors would provide detailed solutions,
                guiding them to solve the questions and clarify their queries.
                Furthermore, we also provide online sessions and physical
                lessons
              </p>
              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Snap, Consult, Learn
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Convenient and Customized Learning
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Tutors readily available to tend to student’s questions
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full flex flex-col xl:flex-row-reverse gap-[30px] items-center"
          >
            <div className="w-full xl:w-[50%] flex justify-center">
              <div className="w-[400px] h-[400px]">
                <img
                  src={Teacher1}
                  alt="img"
                  className="w-full h-full object-fill rounded-[20px]"
                />
              </div>
            </div>
            <div className="w-full xl:w-[50%] flex flex-col gap-[12px]">
              <p>
                After teaching for the past 12 years, both in tuition classes
                and private tutoring, there was a recurring issue that many
                students faced. Due to the limited time at tuition centres and
                private tutoring, students are unable to get all their queries
                and questions answered, and as a result academic improvement
                took a longer time. This led to the start of Tutormore, a
                platform that promotes convenience for both parents and
                students, allowing students to enquire academic queries, be it a
                Mathematics problem sum, Science concept or English Language
                question, all at their own pace and at the comfort of their
                home. By having their queries answered one question at a time,
                students would inevitably progress up the slope of academic
                excellence.
              </p>
              <p>-Daniel Low </p>
              <p>CEO/Founder/Head-Tutor</p>
              {/* <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Fast Support
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Stable API Response
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Reasonable Price
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                User Friendly UX
              </div>

              <div className="flex items-center gap-[7px]">
                <FaCheck className="text-[#f59c36] font-semibold" />
                Regular Feature Update
              </div> */}
            </div>
          </motion.div>
        </motion.div>
        <motion.div
          variants={Animation}
          initial="hidden"
          whileInView={"visible"}
          className="w-full  bg-[#f5f3f5] flex flex-col gap-[20px]"
        >
          {/* <h2 className="font-bold text-[36px] text-center leading-[1.3em]">
            Our Features
          </h2> */}
          {/* <div className="w-full flex flex-col sm:flex-row justify-center gap-[20px] p-[0px] xl:p-[50px] items-center">
            <div className="w-full sm:w-[45%] bg-white flex flex-col gap-[12px] p-[30px]">
              <img src={Img2} alt="img" className="w-[15%]" />
              <h3 className="font-semibold text-[22px]">Lorem Ipsum</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde,
                voluptates tempora id autem tenetur molestias.
              </p>
              <FaArrowRight className="text-[#f59c36] font-semibold" />
            </div>
            <div className="w-full sm:w-[45%] bg-white flex flex-col gap-[12px] p-[30px]">
              <img src={Img2} alt="img" className="w-[15%]" />
              <h3 className="font-semibold text-[22px]">Lorem Ipsum</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde,
                voluptates tempora id autem tenetur molestias.
              </p>
              <FaArrowRight className="text-[#f59c36] font-semibold" />
            </div>
          </div> */}
          {/* <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="border-t border-[#f59c36] p-[20px] flex flex-wrap xl:flex-nowrap justify-center xl:justify-center items-center gap-[30px]">
            <div className="w-full sm:w-[47%] flex items-center gap-[12px]">
              <img src={Img6} alt="img" />
              <div className="flex flex-col gap-[4px]">
                <h2 className="text-[26px] font-semibold">12+</h2>
                <h4>Years of Experience</h4>
              </div>
            </div>
            <div className="w-full sm:w-[47%] flex items-center gap-[12px]">
              <img src={Img5} alt="img" />
              <div className="flex flex-col gap-[4px]">
                <h2 className="text-[26px] font-semibold">900+</h2>
                <h4>Active Students</h4>
              </div>
            </div>
            <div className="w-full sm:w-[47%] flex items-center gap-[12px]">
              <img src={Img4} alt="img" />
              <div className="flex flex-col gap-[4px]">
                <h2 className="text-[26px] font-semibold">900+</h2>
                <h4>Positive Reviews</h4>
              </div>
            </div>
            <div className="w-full sm:w-[47%] flex items-center gap-[12px]">
              <img src={Img3} alt="img" />
              <div className="flex flex-col gap-[4px]">
                <h2 className="text-[26px] font-semibold">800+</h2>
                <h4>Satisfied Customers</h4>
              </div>
            </div>
          </motion.div> */}
          <div className="p-[30px] sm:p-[50px] bg-white flex flex-col gap-[20px]">
            <motion.div
              variants={Animation}
              initial="hidden"
              whileInView={"visible"}
              className="w-full bg-[#f3f3f3] text-[#595959] items-center rounded-[12px] pb-[100px] p-[30px] flex flex-col gap-[30px]"
            >
              <div className="flex flex-col xl:flex-row gap-[15px] items-center justify-between">
                <h2 className="text-[30px]  font-semibold after:h-[20px] after:border-2 after:bg-orange-500 after:absolute after:top-0 after:bottom-0 ">
                  Our Team
                </h2>
                <img className="w-[20%]" src={Logo} />
              </div>
              <div className="w-full flex flex-wrap justify-center xl:flex-nowrap gap-[30px] items-center">
                <div className="w-full sm:w-[45%] text-center xl:w-[25%] flex flex-col items-center gap-[12px] sm:h-[450px] xl:h-[400px]">
                  <div className="w-[300px] h-[300px]">
                    <img
                      src={Teacher1}
                      alt="img"
                      className="w-full h-full object-fill rounded-[12px]"
                    />
                  </div>
                  <h3 className="font-semibold">MR. DANIEL LOW</h3>
                  <p>
                    {" "}
                    Founder, Business development & tutor <br />
                    12 years in tuition industry (since 2012)
                  </p>
                </div>
                <div className="w-full sm:w-[45%] text-center xl:w-[25%] flex flex-col items-center gap-[12px] sm:h-[450px] xl:h-[400px]">
                  <div className="w-[300px] h-[300px]">
                    <img
                      src={Teacher2}
                      alt="img"
                      className="w-full h-full object-fill rounded-[12px]"
                    />
                  </div>
                  <h3 className="font-semibold">MR. JOSEPH LIEW</h3>
                  <p> User Interface/ Tutor</p>
                </div>
                <div className="w-full sm:w-[45%] text-center xl:w-[25%] flex flex-col items-center gap-[12px] sm:h-[450px] xl:h-[400px]">
                  <div className="w-[300px] h-[300px]">
                    <img
                      src={Teacher3}
                      alt="img"
                      className="w-full h-full object-fill rounded-[12px]"
                    />
                  </div>
                  <h3 className="font-semibold">MR. KARL</h3>
                  <p className="text-center">
                    Outreach / Tutor <br />
                    Five years of teaching experience, network and outreach
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-[12px] items-center">
                <h3 className="text-[#4b5c6b] text-[20px] font-semibold text-center">
                  GROUP OF TUTORING STAFFS
                </h3>
                <p className="text-center xl:w-[70%]">
                  Our core team consist of a comprehensive group of teachers
                  with extensive expertise in MOE Syllabus for Primary and
                  Secondary schools in Singapore, to cater to the needs,
                  queries, and questions from all students.
                </p>
              </div>
            </motion.div>
          </div>

          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full p-[15px] xl:p-[50px] flex flex-col gap-[20px] items-center"
          >
            <h2 className="font-bold text-[36px] leading-[1.3em] text-center">
              Pricing plans for Students
            </h2>
            {/* <p>*We help companies of all sizes</p> */}
            <div className="w-full flex-col gap-[20px] xl:gap-[0px] xl:flex-row sm:p-[50px] flex justify-center">
              <div className="w-full xl:w-[33%] border rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]">
                <div className="flex items-center justify-between">
                  <img src={Img7} alt="check" />
                  <p>LEARNER</p>
                </div>
                <h2 className="text-[30px] font-semibold">SGD$29.00 /month</h2>
                <ul className="list-disc px-[15px] flex flex-col gap-[7px]">
                  <li>Up to 35 questions per month</li>
                  <li>
                    Interact daily with Tutors and get your questions solved
                  </li>
                  <li>Primary School (All Levels) P1 to P6</li>
                  <li>Subjects (English, Mathematics and Science)</li>
                  <li>CHAS Card required to subscribe for this category</li>
                </ul>
                <button
                  onClick={() => navigate("/pricing")}
                  className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
                >
                  SIGN UP NOW
                </button>
              </div>
              <div className="w-full xl:w-[33%] border-2 xl:my-[-30px] rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]">
                <div className="flex items-center justify-between">
                  <img src={Img7} alt="check" />
                  <p>STUDENT</p>
                </div>
                <h2 className="text-[30px] font-semibold">SGD$38.00 /month</h2>
                <ul className="list-disc flex flex-col gap-[7px] px-[15px]">
                  <li>Up to 50 questions per month</li>
                  <li>
                    Interact daily with Tutors and get your questions solved
                  </li>
                  <li>Primary School (All Levels) P1 to P6</li>
                  <li>Subjects (English, Mathematics and Science)</li>
                </ul>
                <button
                  onClick={() => navigate("/pricing")}
                  className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
                >
                  SIGN UP NOW
                </button>
              </div>

              <div className="w-full xl:w-[33%] border rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]">
                <div className="flex items-center justify-between">
                  <img src={Img7} alt="check" />
                  <p>APPRENTICE</p>
                </div>
                <h2 className="text-[30px] font-semibold">SGD$56.00 /month</h2>
                <ul className="list-disc px-[15px] flex flex-col gap-[7px]">
                  <li>Up to 80 questions per month</li>
                  <li>
                    Interact daily with Tutors and get your questions solved
                  </li>
                  <li>Primary School (All Levels) P1 to P6</li>
                  <li>Subjects (English, Mathematics and Science)</li>
                </ul>
                <button
                  onClick={() => navigate("/pricing")}
                  className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
                >
                  SIGN UP NOW
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
        <div className="p-[30px] sm:p-[50px] bg-white flex flex-col gap-[20px]">
          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full flex flex-col xl:flex-row gap-[30px] p-[15px] items-center"
          >
            {/* <div className="w-full xl:w-[50%] flex flex-col gap-[20px]">
              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(1)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">
                    How it Will take impact for food & restaurant business.
                  </h3>
                  <FaPlus />
                </div>
                {tabs === 1 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(2)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">Financial Services</h3>
                  <FaPlus />
                </div>
                {tabs === 2 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(3)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">
                    Do you offer a 30 days money-back guarantee?
                  </h3>
                  <FaPlus />
                </div>
                {tabs === 3 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(4)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">
                    Do I need a credit card to Sign up?
                  </h3>
                  <FaPlus />
                </div>
                {tabs === 4 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(5)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">
                    Why does your business need a ChatBot?
                  </h3>
                  <FaPlus />
                </div>
                {tabs === 5 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-[0px]">
                <div
                  onClick={() => handleTabs(6)}
                  className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer"
                >
                  <h3 className="font-semibold">How does I change My Tutor</h3>
                  <FaPlus />
                </div>
                {tabs === 6 ? (
                  <div className="p-[20px] border border-gray-300 rounded-[12px]">
                    <p>
                      A chatbot is always available to serve your customer 24/7.
                      A chatbot can save you a lot of time and money by
                      automating repetitive tasks like qualifying leads.
                    </p>
                  </div>
                ) : null}
              </div>
            </div> */}
            <div className="w-full xl:w-[100%] flex flex-col items-center">
              <div className="xl:w-[80%] rounded-[12px] bg-[#f5f3f5] h-[500px] justify-center items-center gap-[12px] p-[30px] flex flex-col">
                <img src={Img9} alt="img" />
                <h3 className="font-bold text-center leading-6 ">
                  Do you have more questions?
                </h3>
                <p className="font-medium text-center leading-[1.5em]  ">
                  Should you have any queries, you can reach us via Whatsapp or
                  <br />
                  call us at 89382114
                </p>
                {/* <p className="text-center">shoot a direct email</p> */}
                <Link
                  className="w-full flex justify-center"
                  to="mailto:officialtutormore@gmail.com"
                >
                  <button className="sm:w-[80%] px-[30px] py-[12px] rounded-[7px] bg-[#f59c36] text-white">
                    officialtutormore@gmail.com
                  </button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>

        <Footer />
      </div>
    </HomeLayout>
  );
};

export default About;
