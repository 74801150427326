import React, { useEffect, useState } from "react";
import Layout from "./layout/layout";
import { FaPencil, FaSearchengin, FaTrash } from "react-icons/fa6";
import axios from "axios";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";

const TeacherProblem = () => {
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const [problems, setProblems] = useState([]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://tutor-backend.hitoritech.com/api/v1/admin/view-teacher-problems/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res?.data?.Data);
        setProblems(res?.data?.Data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [close, setClose] = useState(false);

  return (
    <Layout>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[90%] p-[25px] h-[90%] bg-white rounded-[12px] overflow-y-scroll hide-scrollbar">
          <div className="w-full flex flex-col xl:flex-row gap-[10px] xl:items-center justify-between">
            <div className="flex flex-col gap-[7px]">
              <h1 className="font-semibold text-[15px]">All Problems</h1>
            </div>
            <div className="flex items-center p-[7px] bg-[#f9fbff] rounded-[50px] gap-[7px]">
              <input
                type="text"
                name="search"
                id="search"
                className=" bg-transparent outline-none border-0 p-0"
                onChange={handleChange}
                value={search}
                autoComplete="false"
              />
              <FaSearchengin />
            </div>
          </div>
          <table className="w-full whitespace-nowrap">
            <tbody>
              <tr
                tabIndex="0"
                className="focus:outline-none h-16 border border-gray-100 rounded">
                <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      PROBLEM ID
                    </p>
                  </div>
                </td>
                {/* <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      NAME
                    </p>
                  </div>
                </td> */}
                <td className="">
                  <div className="flex items-center pl-5">
                    <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                      TEXT
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      CREATED AT
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      STATUS
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      IMAGE
                    </p>
                  </div>
                </td>
                <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      VIEW
                    </p>
                  </div>
                </td>

                {/* <td className="pl-5">
                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                      ACTIONS
                    </p>
                  </div>
                </td> */}
              </tr>
              {problems.map((item, index) => {
                return (
                  <tr
                    key={index}
                    tabIndex="0"
                    className="focus:outline-none min-h-10 h-10 border border-gray-100 rounded">
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.id}
                        </p>
                      </div>
                    </td>
                    {/* <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.name}
                        </p>
                      </div>
                    </td> */}
                    <td className="">
                      <div className="flex items-center pl-5">
                        <p className="text-[12px] font-semibold leading-none text-gray-700 mr-2">
                          {item?.text}
                        </p>
                      </div>
                    </td>
                    <td className="pl-5">
                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                          {moment(item?.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </td>
                    <td className="pl-5">
                      <div className="flex items-center">
                        <p className="text-[12px] font-semibold leading-none text-gray-600 ml-2">
                          {item?.status}
                        </p>
                      </div>
                    </td>
                    <td className="pl-5">
                      <div className="flex w-[200px] h-[100px] xl:w-[100px] items-center">
                        <img
                          src={`https://tutor-backend.hitoritech.com/${item?.attachment_url}`}
                          alt="img"
                          className="w-full h-full object-cover mb-[12px]"
                        />
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        setClose(true);
                      }}
                      className="pl-5">
                      <div className="flex items-center">
                        <button className="focus:ring-2 focus:ring-offset-2 text-sm leading-none text-white font-medium py-3 px-5 bg-[#f59c36] rounded focus:outline-none">
                          View
                        </button>
                      </div>
                    </td>

                    {/* <td className="pl-5">
                      <div className="flex gap-[35px] items-center">
                        <FaTrash />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {close === true ? (
          <div className="bg-white w-full sm:w-[70%] xl:w-[30%] gap-8 h-full hide-scrollbar overflow-y-scroll fixed pb-[100px]  right-0 p-[20px] cursor-pointer ">
            <button
              onClick={() => {
                setClose(false);
              }}>
              {" "}
              Close
            </button>
            <p className="text-[25px] font-bold w-[100%]">About Problems</p>

            <div>
              <p className="text-[25px] font-bold w-[100%] mt-[30px]">
                Activity
              </p>
              <p className="text-[13px]">Past few question asked</p>
              <p className="w-[100%] p-[10px] text-[white]"></p>
              {problems?.map((item) => (
                <div
                  className={`flex border-2 bg-[#F59C36] ${
                    item?.attachment_url ? "rounded-[20px]" : "rounded-[10px]"
                  } mx-[10px] mt-[10px]`}>
                  {item?.attachment_url ? (
                    <img
                      className="w-[30%] rounded-[17px]"
                      src={`https://tutor-backend.hitoritech.com/${item?.attachment_url}`}
                      alt=""
                    />
                  ) : null}
                  <div className="p-[12px] flex flex-col gap-[7px]">
                    <p className="m-0 text-white font-bold">Question:</p>
                    <p className="w-[100%]  text-[white]">{item?.text}</p>
                    <p className=" text-white font-semibold flex items-center gap-[7px]">
                      Status:{" "}
                      <span className="font-normal">{item?.status}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default TeacherProblem;
