import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  teacherLoginValues: {
    email: "",
    password: "",
  },
  teacherRegisterValues: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  },
};

const teacherAuthSlice = createSlice({
  name: "teacherAuthSlice",
  initialState,
  reducers: {
    setTeacherLoginValues: (state, action) => {
      state.teacherLoginValues = action.payload;
    },
    setTeacherRegisterValues: (state, action) => {
      state.teacherRegisterValues = action.payload;
    },
  },
});

export const { setTeacherLoginValues, setTeacherRegisterValues } =
  teacherAuthSlice.actions;

export default teacherAuthSlice.reducer;
