import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginImg from "../assets/admin/login.png";
import Img2 from "../assets/loginImages/google.png";
import Img3 from "../assets/loginImages/linkedin.avif";
import { useDispatch, useSelector } from "react-redux";
import { setAdminLoginValues } from "../state/redux/adminAuthSlice";
import { ToastContainer } from "react-toastify";
import { showToastError } from "../utils/toastify/toastify";
const Login = () => {
  const navigate = useNavigate();
  const adminLoginValues = useSelector(
    (state) => state.adminAuthSlice.adminLoginValues
  );
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setAdminLoginValues({ ...adminLoginValues, [name]: value }));
  };
  const handleSubmit = async (e) => {
    const reqBody = {
      email: adminLoginValues.email,
      password: adminLoginValues.password,
    };
    console.log(reqBody);
    e.preventDefault();
    await axios({
      method: "POST",
      url: "https://tutor-backend.hitoritech.com/api/v1/admin/admin-login",
      headers: {
        "Content-Type": "Application/json",
      },
      data: reqBody,
    })
      .then((response) => {
        localStorage.setItem("adminToken", response.data.token);
        navigate("/admin/");
      })
      .catch((err) => {
        console.log(err);
        showToastError(err?.response?.data?.message?.toString());
      });
  };
  return (
    <div className="h-screen overflow-y-scroll hide-scrollbar  relative z-[1] flex flex-col gap-[30px] lg:flex-row lg:justify-between items-center p-[30px] sm:p-[50px] ">
      <ToastContainer />
      <div className=" w-full lg:w-[50%] flex flex-col gap-[20px] items-center justify-center">
        <img src={LoginImg} alt="loginImg" className="w-80%" />
      </div>
      <div className="w-full lg:w-[50%]  flex justify-center items-center">
        <div className="rounded-[50px] shadow-md w-full sm:w-[70%] bg-[#FFFFFF4D] flex flex-col items-center justify-center p-[30px] sm:p-[50px]">
          <h2 className="text-[30px] font-bold text-center">Login for Admin</h2>
          <div className="flex flex-col my-2 gap-2 w-full items-center">
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="false"
                value={adminLoginValues.email}
                onChange={handleChange}
                className="bg-white w-full px-2 py-2 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                autoComplete="false"
                value={adminLoginValues.password}
                onChange={handleChange}
                className="bg-white w-full px-2 py-2 rounded-md"
              />
            </div>
            <Link to="/admin/forgot-password" className="w-[90%]">
              <h3 className="w-full sm:w-[90%]">Forgot Password?</h3>
            </Link>
            <button
              onClick={handleSubmit}
              className="bg-[#f25019] text-white w-[90%] py-2 my-2 rounded-md font-semibold"
            >
              Login
            </button>
            {/* <p className="font-semibold">or Continue with</p>
            <div className="my-2 flex justify-around w-[90%] gap-2">
              <div className="w-full flex justify-center gap-5">
                <Link
                  to="#"
                  className="w-[50%] bg-white rounded-[5px] flex justify-center px-[35px]  py-2 cursor-pointer text-lg"
                >
                  <img src={Img2} alt="google" className="w-[20px]" />
                </Link>
                <Link
                  to="#"
                  className="w-[50%] bg-white rounded-[5px] flex justify-center px-[35px]  py-2 cursor-pointer text-lg"
                >
                  <img src={Img3} alt="google" className="w-[20px]" />
                </Link>
              </div>
            </div> */}
            <div className="w-[90%] text-center text-[15px] mt-2 flex flex-col items-center justify-between">
              <p>
                Don't have an Account Yet?
                <Link to="/admin/register">
                  <span className="font-semibold"> Register For Free</span>
                </Link>
              </p>
              <p className="font-semibold mt-5 text-center text-[15px]">
                Try to be Login as Teacher ?{" "}
                <Link to="/teacher/login" className="text-blue-700">
                  Log In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
