import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  FaMagnifyingGlass,
  FaAnglesRight,
  FaEllipsis,
  FaRegImages,
  FaRegFileLines,
  FaX,
} from "react-icons/fa6";
import Header from "../common/Header";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  setCurrentChat,
  setNewMessage,
  setStudentMessages,
  setUserChats,
  setLastMessage,
} from "../state/redux/adminReduxSlice/adminStudentMessageSlice";
import { io } from "socket.io-client";
import axios from "axios";
import Webcam from "react-webcam";
import Layout from "./layout/layout";
function ViewStudentChats() {
  const { id } = useParams();
  const token = localStorage.getItem("adminToken");
  const dispatch = useDispatch();

  const studentMessageSlice = useSelector(
    (state) => state.adminStudentMessageSlice
  );
  const userChats = useSelector(
    (state) => state.adminStudentMessageSlice.userChats
  );
  const messages = useSelector(
    (state) => state.adminStudentMessageSlice.messages
  );
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://tutor-backend.hitoritech.com/api/v1/admin/fetch-chats-student/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res, "res");
        dispatch(setUserChats(res.data.chat));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(userChats, "lavi");

  const currentChat = useSelector(
    (state) => state.studentMessageSlice.currentChat
  );
  const loadStudent = useSelector((state) => state.loadStudentSlice);
  const socket = useSelector((state) => state.loadStudentSlice.socket);

  const [attachment, setAttachment] = useState(null);
  const currentConversation = useSelector(
    (state) => state.studentMessageSlice.currentChat
  );
  const [uploaded, setUploaded] = useState(false);
  const [incomingMessage, setIncomingMessage] = useState(null);
  const [image, setImage] = useState(null);

  const handleChatIndex = (chatid) => {
    console.log(chatid, "chatid");
    const chatIndex = studentMessageSlice.userChats.findIndex(
      (chat) => chat.chatid === chatid
    );
    console.log(chatIndex);
    if (chatIndex > -1) {
      const updatedChats = [
        studentMessageSlice.userChats[chatIndex],
        ...studentMessageSlice.userChats.slice(0, chatIndex),
        ...studentMessageSlice.userChats.slice(chatIndex + 1),
      ];
      dispatch(setUserChats(updatedChats));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const blobUrl = URL.createObjectURL(file);
    console.log(blobUrl);
    const newImage = { file: file, url: blobUrl };
    setImage(newImage);
    console.log(newImage, "imageUrl");
  };

  useEffect(() => {
    console.log(currentConversation, "currentConversation");
  }, [currentConversation]);

  useEffect(() => {
    if (socket === null) return;
    const handleNewMessage = (res) => {
      setIncomingMessage(res);
    };
    socket.on("resStudent", handleNewMessage);
    // console.log(resStudent);
    return () => {
      socket.off("resStudent", handleNewMessage);
    };
  }, [socket]);

  const handleProblem = (res) => [console.log(res, "newProblem")];

  useEffect(() => {
    console.log(
      incomingMessage,
      currentConversation?.chatid,
      incomingMessage?.message?.newMessage?.text,
      incomingMessage &&
        currentConversation?.chatid ===
          incomingMessage.message?.newMessage.chatid,
      "koke"
    );
    if (
      incomingMessage &&
      currentConversation?.chatid === incomingMessage.message.newMessage.chatid
    ) {
      handleChatIndex(incomingMessage.message.newMessage.chatid);
      console.log(incomingMessage?.message?.newMessage.newMessage, "res");
      dispatch(setNewMessage(incomingMessage?.message?.newMessage));
      dispatch(
        setLastMessage({
          id: incomingMessage?.message?.newMessage.chatid,
          message: incomingMessage?.message?.newMessage.text,
        })
      );
    }
  }, [incomingMessage]);

  const [resid, setResId] = useState();

  const [conversations, setConversations] = useState([
    {
      id: 1,
      name: "Md. Rofiq",
      messages: [
        {
          text: "Jam nonumy eirmod tempor invidunt ut labore et dolore magnaaliquyam erat consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua",
          isUser: false,
          senderName: "Md. Rofiq",
          date: new Date("2022-04-03T10:30:00").toLocaleString(),
        },
      ],
    },
    {
      id: 2,
      name: "Roshid Khan",
      messages: [
        {
          text: "Jam nonumy eirmod tempor invidunt ut labore et dolore magnaaliquyam erat consetetur sadipscing elitr sed diam nonumyeirmod tempor invidunt ut labore et dolore magnaaliquyam erat sed diam voluptua",
          isUser: false,
          senderName: "Roshid Khan",
          date: new Date("2022-04-03T11:30:00").toLocaleString(),
        },
      ],
    },
  ]);

  const fetchMessages = (chatid) => {
    console.log(chatid, "chatid");
    axios({
      method: "Get",
      url: `https://tutor-backend.hitoritech.com/api/v1/admin/view-student-chat/${chatid}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res?.data?.chat, "messages fetched");
        dispatch(setStudentMessages(res?.data?.chat));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(messages, "messg");
  const fetchMessagesTop = async (chat) => {
    dispatch(setCurrentChat(chat));
    console.log(currentConversation, "currentConversation");
    console.log("Received chat object:", chat);

    setResId(chat.members.teacherid);
    console.log(currentConversation, "hello");

    fetchMessages(chat.chatid);
  };
  const [inputText, setInputText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);

  const currentConversationName = conversations.find(
    (conv) => conv.id === currentConversation
  )?.name;

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchInput(searchValue);
    const filtered = conversations.filter((conv) =>
      conv.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredConversations(filtered);
  };

  const displayConversations =
    searchInput.length > 0 ? filteredConversations : conversations;

  const formatDate = (dateString) => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return messageDate.toLocaleDateString(undefined, options);
    }
  };

  const scroll = useRef();
  useEffect(() => {
    scroll.current?.scrollIntoView({ behaviour: "smooth" });
  }, [messages]);

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
  };
  const [isCamActive, setIsCamActive] = useState(false);
  const [captured, setCaptured] = useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1], // Extract mime type (e.g., 'image/jpeg')
      bstr = atob(arr[1]), // Decode Base64 binary string
      n = bstr.length,
      u8arr = new Uint8Array(n); // Create a new array to hold the byte values

    // Populate the array with byte values
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Return a Blob created from the TypedArray
    return new Blob([u8arr], { type: mime });
  }

  return (
    <Layout>
      <div className="h-full overflow-y-scroll hide-scrollbar flex flex-col bg-white">
        <div className="w-full mt-[20px] overflow-y-scroll hide-scrollbar h-full">
          <div className=" bg-white w-full h-full overflow-y-scroll hide-scrollbar">
            <div className=" xl:py-[20px] h-full overflow-y-scroll hide-scrollbar flex justify-center bg-[#f6a345]  ">
              <div className="xl:w-[90%] overflow-y-scroll hide-scrollbar h-screen bg-white py-[0px] rounded-[12px]">
                <div className="flex flex-col h-full overflow-y-scroll hide-scrollbar justify-center w-full sm:flex-row">
                  <div className="w-full sm:w-[40%] sm:h-full p-[25px] m-b30">
                    <div className="p-4 bg-white h-full overflow-y-scroll hide-scrollbar ">
                      <div className="flex justify-between items-center">
                        <input
                          type="text"
                          placeholder="Input Here"
                          value={searchInput}
                          onChange={handleSearch}
                          style={{ padding: "10px 25px" }}
                          className="w-full border-none border-gray-400 rounded-3xl  mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                        />
                        <span
                          style={{
                            position: "relative",
                            right: "35px",
                            cursor: "pointer",
                            color: "#A0A0A0",
                          }}>
                          <FaMagnifyingGlass />
                        </span>
                      </div>
                      <div>
                        <h4 className="mt-4 text-[#8231D3] font-jost font-semibold text-xl leading-31.79 text-center">
                          <span className="border-b-2 border-[#8231D3]">
                            Private Chat
                          </span>
                        </h4>
                      </div>
                      <ul className="job-list-container">
                        {userChats?.map((item) => (
                          <li key={item.index} className="mt-4">
                            <Link
                              to={"#"}
                              className={`conversation-item ${
                                item.chatid === currentConversation
                                  ? "bg-blue-500 text-black"
                                  : ""
                              }`}
                              onClick={() => {
                                fetchMessagesTop(item);
                              }}>
                              <div className="flex bg-slate-300 w-full h-[80px]">
                                <div className="w-[20%] m-[10px] flex justify-center items-center">
                                  <div className="w-[60px] h-[60px] flex justify-center items-center rounded-[50%]">
                                    <img src="" alt="" />
                                  </div>
                                </div>
                                <h6 className=" font-jost font-semibold text-15 leading-21.68 text-left text-[#0A0A0A] p-[10px]">
                                  <h3 className="text-[20px] mb-[-2px]">
                                    {item.receptionDetails?.name}
                                  </h3>
                                  <p className="text-gray-600">
                                    {item.last_message}
                                  </p>
                                </h6>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="w-full sm:w-[70%] overflow-y-scroll hide-scrollbar h-full p-[25px] ">
                    {uploaded ? (
                      <div className="w-full h-full bg-gray-500  overflow-hidden flex justify-between p-[25px] flex-col items-center">
                        {captured === true ? (
                          <img
                            src={`https://tutor-backend.hitoritech.com/${attachment}`}
                            alt=""
                            className="w-[450px]"
                          />
                        ) : (
                          <img
                            src={`https://tutor-backend.hitoritech.com/${attachment}`}
                            alt=""
                            className="w-[450px]"
                          />
                        )}
                        <div className="message-input flex items-center mt-4 w-[80%]">
                          <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Type your message..."
                            className="w-full border-none border-gray-400 rounded-3xl  bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                          />
                          <div
                            //   onClick={handleSendMessage}
                            className="bg-purple-800 text-white  rounded-full ml-2 w-12 h-10 flex items-center justify-center">
                            <FaAnglesRight className="text-lg cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="p-4 bg-white h-full flex flex-col gap-[0px]">
                        <div className="flex justify-between items-center p-4">
                          <div>
                            <h2 className="text-lg font-jost font-semibold leading-21.68 text-left text-[#0A0A0A]">
                              {currentConversationName}
                            </h2>
                            <p className="text-[#0A0A0A] text-xs">Online</p>
                          </div>
                          <div>
                            <FaEllipsis />
                          </div>
                        </div>
                        {isCamActive === true ? (
                          <>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                            />
                            <div className="flex justify-center items-center gap-[15px]">
                              {/* <button onClick={capture}>Capture Photo</button> */}
                              <FaX
                                className="cursor-pointer"
                                onClick={() => setIsCamActive(false)}
                              />
                            </div>
                            {/* {imgSrc && <img src={imgSrc} alt="img" />} */}
                          </>
                        ) : (
                          <div className="mt-4 h-full flex flex-col overflow-y-scroll">
                            {messages?.map((message, index) => (
                              <div
                                ref={scroll}
                                key={index}
                                className={`message ${
                                  message.type === "teacher"
                                    ? " text-white p-4"
                                    : "bg-[#FBFBFB]  p-4"
                                }`}
                                style={{
                                  display: " flex",
                                  flexDirection: "column",
                                }}>
                                {index === 0 ||
                                formatDate(message?.createdAt) !==
                                  formatDate(
                                    studentMessageSlice.messages[index - 1]
                                      ?.createdAt
                                  ) ? (
                                  <div className="mb-2 text-center text-sm font-bold text-[#747474]">
                                    {formatDate(message?.createdAt)}
                                  </div>
                                ) : null}
                                {/* <div className="">
                            <div className=" ">
                              <div>
                                {message.isUser ? (
                                  <div className="flex justify-between items-center">
                                    <span className=" font-jost font-semibold text-15 leading-21.68  text-[#0A0A0A] ">
                                      You
                                    </span>
                                    <div className="text-xs text-gray-500 ">
                                      {formatDate(message.date)}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center ">
                                    <img
                                      src={message.profileImage}
                                      alt="Profile"
                                      className="w-6 h-6 rounded-full mr-2"
                                    />
                                    <div className="flex justify-start items-center">
                                      <span className="font-jost font-semibold text-15 leading-21.68 text-left text-[#0A0A0A]">
                                        {message.senderName}
                                      </span>
                                      <div className="text-xs text-gray-500 ml-2">
                                        {formatDate(message.date)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div> */}
                                <div
                                  // className={`mb-2 ${
                                  //   message.type !== "teacher"
                                  //     ? "bg-[#F4F5F7] text-white"
                                  //     : "bg-[#404040] text-black"
                                  // }  p-4 mt-2 w-auto rounded-r-lg rounded-b-lg ml-2`}

                                  className={`${
                                    message?.type !== "teacher"
                                      ? "p-[10px] self-end flex flex-col gap-[5px] bg-[#f4f5f7] text-black rounded-t-[10px] rounded-bl-[10px]"
                                      : "p-[10px] self-start flex flex-col gap-[5px] bg-[#404040] text-black rounded-t-[10px] rounded-br-[10px]"
                                  }`}>
                                  <p
                                    className={`font-jost font-medium text-15 leading-21.68 ${
                                      message?.type !== "teacher"
                                        ? " text-[#0A0A0A]"
                                        : "text-[#FFFFFF]"
                                    }`}>
                                    {message?.attachment ? (
                                      <p>
                                        <img
                                          src={`https://tutor-backend.hitoritech.com/${message?.attachment_url}`}
                                          alt=""
                                        />
                                      </p>
                                    ) : null}
                                    {message?.text}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* <div className="message-input flex items-center mt-4">
                          <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Type your message..."
                            className="w-full border-none border-gray-400 rounded-3xl p-2 mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                          />

                          <div className="bg-[#F4F5F7]  text-[#747474]  rounded-full ml-2 w-12 h-10 flex items-center justify-center ">
                            <FaRegImages
                              onClick={() => {
                                setIsCamActive(true);
                              }}
                              className="text-lg cursor-pointer"
                            />
                          </div>
                          <div className="bg-[#F4F5F7] text-[#747474]  rounded-full ml-2 w-12 h-10  flex items-center justify-center ">
                            <div className="relative">
                              <label
                                title="Click to upload"
                                for="button2"
                                className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                              >
                                <div className="relative">
                                  <FaRegFileLines className="text-lg cursor-pointer" />
                                </div>
                              </label>
                              <input
                                type="file"
                                name="button2"
                                id="button2"
                                className="hidden"
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                          <div className="bg-purple-800 text-white  rounded-full ml-2 w-12 h-10 flex items-center justify-center">
                            <FaAnglesRight className="text-lg cursor-pointer" />
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ViewStudentChats;
