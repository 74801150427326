import React from "react";
import planFeatures from "../../utils/constants";
import Feature from "./Feature";
import Img7 from "../../assets/about/img8.png";
import axios from "axios";

const PricingCard = ({ type, cost }) => {
  const token = localStorage.getItem("studentToken");

  const handlePlanPurchase = async () => {
    if (cost === "10") {
      try {
        await axios({
          method: "POST",
          url: "https://tutor-backend.hitoritech.com/api/v1/payment/ten-chats",
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            window.location.href = response.data.url;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (cost === "15") {
      try {
        await axios({
          method: "POST",
          url: "https://tutor-backend.hitoritech.com/api/v1/payment/eighteen-chats",
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            window.location.href = response.data.url;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="w-full h-full max-w-sm p-4 bg-white border border-[#F59C3633] hover:border-[#F59C36] rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex items-center justify-between">
        <img className="mb-2 pb-2" src={Img7} alt="check" />

        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
          {type}
        </h5>
      </div>
      <div className="flex items-baseline text-gray-900 dark:text-white">
        <span className="text-3xl font-semibold">$</span>
        <span className="text-5xl font-extrabold tracking-tight">{cost}</span>
        <span className="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">
          /month
        </span>
      </div>
      <ul className="space-y-3 my-5">
        {planFeatures[type]?.map((feature, index) => {
          return <Feature text={feature} key={index} />;
        })}
      </ul>
      <button
        type="button"
        className="text-white bg-[#F59C36] hover:bg-[#F59C36] focus:ring-4 focus:outline-none focus:ring-[#F59C36] dark:bg-[#F59C36] dark:hover:bg-[#F59C36] dark:focus:ring-[#F59C36] font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
        onClick={handlePlanPurchase}
      >
        SIGN UP NOW
      </button>
    </div>
  );
};

export default PricingCard;
