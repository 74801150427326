import { createSlice } from "@reduxjs/toolkit";
import {
  createChat,
  fetchMessages,
  fetchStudentChats,
  sendTextMessage,
} from "./studentThunkFunctions/studentThunkFunctions";

const initialState = {
  messages: [],
  isMessageLoading: false,
  messageError: null,
  userChats: null,
  isUserChatsLoading: false,
  userChatsError: null,
  chatId: null,
  isChatIdLoading: false,
  chatIdError: false,
  sendTextMessageError: null,
  newMessage: null,
  currentChat: null,
};
const studentMessageSlice = createSlice({
  name: "studentMessageSlice",
  initialState,
  reducers: {
    setUserChats: (state, action) => {
      state.userChats = action.payload;
    },
    setIsUserChatsLoading: (state, action) => {
      state.isUserChatsLoading = action.payload;
    },
    setUserChatsError: (state, action) => {
      state.userChatsError = action.payload;
    },
    setStudentMessages: (state, action) => {
      state.messages = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setChatInactive: (state, action) => {
      const { id } = action.payload;
      const chatIndex = state.userChats.findIndex((chat) => chat.chatid === id);
      // console.log("problem", state.userChats[chatIndex]);
      state.userChats[chatIndex].status = "i=active";
    },
    setIsMessageLoading: (state, action) => {
      state.isMessageLoading = action.payload;
    },
    setMessageError: (state, action) => {
      state.messageError = action.payload;
    },
    setSendTextMessageError: (state, action) => {
      state.sendTextMessageError = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
      state.messages = [...state.messages, action.payload];
    },
    setTextMessage: (state, action) => {
      state.textMessage = action.payload;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    setLastMessage: (state, action) => {
      const { id, message } = action.payload;
      const chatIndex = state.userChats.findIndex((chat) => chat.chatid === id);
      console.log(state.userChats);
      state.userChats[chatIndex].last_message = message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChat.pending, (state) => {
        state.isUserChatsLoading = true;
      })
      .addCase(createChat.fulfilled, (state) => {
        state.isUserChatsLoading = false;
      })
      .addCase(createChat.rejected, (state, action) => {
        state.isUserChatsLoading = false;
        state.userChatsError = action.payload || "Failed to create a chat";
      })
      .addCase(fetchMessages.pending, (state) => {
        state.isMessageLoading = true;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.isMessageLoading = false;
        state.messageError = action.payload || "Failed to fetch the messages";
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.isMessageLoading = false;
        state.messages = action.payload;
      })
      .addCase(fetchStudentChats.pending, (state) => {
        state.isUserChatsLoading = true;
      })
      .addCase(fetchStudentChats.rejected, (state, action) => {
        state.isUserChatsLoading = false;
        state.userChatsError =
          action.payload || "Failed to fetch the user Chats";
      })
      .addCase(fetchStudentChats.fulfilled, (state, action) => {
        state.isUserChatsLoading = false;
        state.chatId = action.payload[0]?.chatid;
        console.log(action.payload[0]?.chatid, "hey");
        state.userChats = action.payload;
      })
      .addCase(sendTextMessage.fulfilled, (state, action) => {
        state.isMessageLoading = false;
        state.newMessage = action.payload;
        console.log(state.messages, "helog");
      })

      .addCase(sendTextMessage.pending, (state, action) => {
        state.isMessageLoading = true;
        state.sendTextMessageError = null;
      })
      .addCase(sendTextMessage.rejected, (state, action) => {
        state.isMessageLoading = false;
        state.sendTextMessageError = action.payload || "Failed to send message";
      });
  },
});

export const {
  setUserChats,
  setIsUserChatsLoading,
  setUserChatsError,
  setStudentMessages,
  setChatId,
  setIsMessageLoading,
  setMessageError,
  setSendTextMessageError,
  setNewMessage,
  setTextMessage,
  setChatInactive,
  setLastMessage,
  setCurrentChat,
} = studentMessageSlice.actions;
export default studentMessageSlice.reducer;
