import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: null,
  socket: null,
};

const loadTeacherSlice = createSlice({
  name: "loadTeacherSlice",
  initialState,
  reducers: {
    setTeacherSlice: (state, action) => {
      state.user = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
  },
});

export const { setTeacherSlice, setSocket } = loadTeacherSlice.actions;

export default loadTeacherSlice.reducer;
