import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginImg from "../assets/tutor/img.png";
import Img2 from "../assets/loginImages/google.png";
import Img3 from "../assets/loginImages/linkedin.avif";
import { useDispatch, useSelector } from "react-redux";
import { setTeacherLoginValues } from "../state/redux/teacherAuthSlice";
import { ToastContainer } from "react-toastify";
import { showToastError, showToastSuccess } from "../utils/toastify/toastify";
import Img from "../assets/Tutormore logo A (1).png";
const TeacherForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPasswordValues, setForgotPasswordValues] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForgotPasswordValues({ ...forgotPasswordValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    const reqBody = {
      email: forgotPasswordValues.email,
    };
    console.log(reqBody);
    e.preventDefault();
    await axios({
      method: "POST",
      url: "https://tutor-backend.hitoritech.com/api/v1/teacher/teacher-reset-password",
      headers: {
        "Content-Type": "Application/json",
      },
      data: reqBody,
    })
      .then((response) => {
        console.log(response);
        showToastSuccess(response?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        showToastError(err?.response?.data?.message?.toString());
      });
  };
  return (
    <div className="h-screen overflow-y-scroll hide-scrollbar  relative z-[1] flex flex-col gap-[30px] lg:flex-row lg:justify-between items-center p-[30px] sm:p-[50px] ">
      <ToastContainer />
      <div className=" w-full lg:w-[50%] flex flex-col gap-[20px] items-center justify-center">
        <div className="xl:w-[500px] xl:h-[200px]">
          <img src={Img} alt="loginImg" className="w-full h-full object-fill" />
        </div>
      </div>
      <div className="w-full lg:w-[50%]  flex justify-center items-center">
        <div className="rounded-[50px] shadow-md w-full sm:w-[70%] bg-[#FFFFFF4D] flex flex-col items-center justify-center p-[30px] sm:p-[50px]">
          <h2 className="text-[30px] font-bold text-center">
            Forgot Password?
          </h2>
          <div className="flex flex-col my-2 gap-2 w-full items-center">
            <div className="flex flex-col gap-2 w-[90%]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="false"
                value={forgotPasswordValues.email}
                onChange={handleChange}
                className="bg-white w-full px-2 py-2 rounded-md"
              />
            </div>

            <button
              onClick={handleSubmit}
              className="bg-[#f25019] text-white w-[90%] py-2 my-2 rounded-md font-semibold"
            >
              Login
            </button>

            <div className="w-[90%] text-center text-[15px] mt-2 flex flex-col items-center justify-between">
              <Link to="/teacher/login">
                <span className="font-semibold"> Back To Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherForgotPassword;
