import React, { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTopButton = ({ scrollContainerRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const listenToScroll = () => {
    const heightToHidden = 30;
    const scrollTop = scrollContainerRef.current
      ? scrollContainerRef.current.scrollTop
      : 0;

    if (scrollTop > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", listenToScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", listenToScroll);
      }
    };
  }, [scrollContainerRef]);

  return (
    <div className="relative">
      {isVisible && (
        <button
          onClick={goToBtn}
          className="p-3 rounded-md z-[99] bg-orange-500 hover:bg-orange-600 text-white fixed right-5 bottom-5"
        >
          <IoIosArrowUp />
        </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
