import React, { useState } from "react";
import {
  FaAddressBook,
  FaBars,
  FaRegPaperPlane,
  FaWrench,
} from "react-icons/fa";
import { FaArrowRightFromBracket, FaUserGroup, FaX } from "react-icons/fa6";
import logo from "../../assets/header/header.png";
import { Link, useNavigate } from "react-router-dom";
const Header = () => {
  const [tabs, setTabs] = useState(false);
  const handleTabs = () => {
    setTabs((prev) => !prev);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin/login");
  };
  return (
    <div className="w-full p-[15px] xl:p-[30px] bg-white flex items-center justify-between">
      <img src={logo} alt="logo" className="w-[150px] xl:hidden" />
      {tabs === true ? (
        <FaX onClick={handleTabs} />
      ) : (
        <FaBars onClick={handleTabs} className="xl:hidden" />
      )}
      {tabs === true ? (
        <div
          className={`absolute top-0 right-0 bg-[#f59e36] w-full sm:w-[50%] h-screen overflow-y-scroll hide-scrollbar translate-x-0 ${
            tabs == true ? "translate-x-[0px] transition-all" : "translate-x-50"
          }`}
        >
          <div className="p-[15px] flex justify-end text-white">
            <FaX onClick={handleTabs} />
          </div>
          <div className="p-[30px] h-full flex flex-col items-center justify-between gap-[25px]">
            <div className="flex w-full flex-col gap-[20px] items-center">
              <div className="w-full flex flex-col gap-[12px] text-[12px]">
                <Link
                  to="/admin/students"
                  className="w-full p-[12px] bg-white text-[#f59e36] rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center"
                >
                  <FaUserGroup className="text-forthColor" /> Students Info
                </Link>
                <Link
                  to="/admin/teachers"
                  className="w-full p-[12px] bg-white text-[#f59e36] rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center"
                >
                  <FaRegPaperPlane className="text-forthColor" /> Teachers Info
                </Link>

                <Link
                  to={"/admin/accounts"}
                  className="p-[12px] bg-white text-[#f59e36] rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center"
                >
                  <FaAddressBook className="text-forthColor" /> Accounts
                </Link>
                <button className="p-[12px] bg-white text-[#f59e36] rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center">
                  <FaWrench className="text-forthColor" />
                  Settings
                </button>
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="w-full text-[12px] bg-white text-[#f59e36] p-[12px] rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center"
            >
              <FaArrowRightFromBracket className="text-forthColor" /> Log Out
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
