export default {
  LEARNER: [
    "Up to 35 questions per month",
    "Interact daily with Tutors and get your questions solved",
    "Primary School (All levels) P1 to P6",
    "Subjects (English, Mathematics and Science)",
    "CHAS card required to subscribe for this category",
  ],
  STUDENT: [
    "Up to 50 questions per month",
    "Interact daily with Tutors and get your questions solved",
    "Primary School (All levels) P1 to P6",
    "Subjects (English, Mathematics and Science)",
  ],
  APPRENTICE: [
    "Up to 80 questions per month",
    "Interact daily with Tutors and get your questions solved",
    "Primary School (All levels) P1 to P6",
    "Subjects (English, Mathematics and Science)",
  ],
};
