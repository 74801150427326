import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaAnglesRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Socket } from "socket.io-client";
import Layout from "./layout/layout";
import { useNavigate } from "react-router-dom";
import { showToastError } from "../utils/toastify/toastify";
import { setStudentButtons } from "../state/sidebarSlice";
const AskQuestion = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStudentButtons("askQuestion"));
  }, []);
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("studentToken");
  const handleImageChange = (e) => {
    const blobUrl = URL.createObjectURL(e.target.files[0]);
    setImage({ file: e.target.files[0], url: blobUrl });
  };
  console.log(image);
  const socket = useSelector((state) => state.loadStudentSlice.socket);
  const formData = new FormData();
  formData.append("file", image?.file);
  formData.append("text", text);
  const handleProfileSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `https://tutor-backend.hitoritech.com/api/v1/student/create-problem`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        console.log(res, "DATA UPDATED");
        socket.emit("newProblem", res.data.Data);
        navigate("/student/problems");
      })
      .catch((err) => {
        console.log(err, "data update error");
        showToastError(err?.response?.data?.message?.toString());
        navigate("/student/pricing");
      });
  };

  return (
    <Layout>
      <div className="rounded-[20px] bg-[#F59C36]  h-[80vh] m-[5%] relative p-[5%]">
        <div className="border-4 border-white w-[100%] h-[100%] rounded-[18px] flex flex-col lg:items-center justify-center px-[20px]">
          <h2 className="text-white lg:text-[50px] text-[25px] font-semibold mb-[5%] text-center">
            what is your question?
          </h2>
          <div className="relative mb-[5%] w-full sm:max-w-[50%] self-center">
            <label
              title="Click to upload"
              for="button2"
              className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
            >
              <div className="w-max relative">
                <img
                  className="w-12"
                  src="https://www.svgrepo.com/show/485545/upload-cicle.svg"
                  alt="file upload icon"
                  width="512"
                  height="512"
                />
              </div>
              <div className="relative ">
                <span className="block text-base font-semibold relative text-blue-900 group-hover:text-blue-500">
                  {image ? image?.file?.name : "Upload a file"}
                </span>
                <span className="mt-0.5 block text-sm text-gray-500">
                  {image ? "Uploaded" : "Max 2 MB"}
                </span>
              </div>
            </label>
            <input
              type="file"
              name="button2"
              id="button2"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
          <div class="mb-3 pt-0 w-full flex items-center">
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Placeholder"
              className="px-3 py-3 placeholder-blue Gray-300 text-blueGray-600 relative bg-white rounded-2xl text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            />
            <button
              onClick={handleProfileSubmit}
              className="bg-white   rounded-full ml-2 w-12 h-10 flex items-center justify-center"
            >
              <FaAnglesRight className="text-lg cursor-pointer" />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AskQuestion;
