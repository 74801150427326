import React from "react";
import HomeLayout from "./layout/HomeLayout";
import Poster from "../assets/home/Poster.png";
import RightArrow from "./svg/RightArrow";
import Footer from "../common/Footer";
import MessageIcon from "./svg/MessageIcon";
import Slider from "../common/slider/Slider";
import { Link, useNavigate } from "react-router-dom";
import HomePricingCard from "./components/HomePricingCard";
import Mobile from "../assets/mobile.png";
import Idea from "../assets/idea.png";
import { motion } from "framer-motion";

const Home = () => {
  const scrollToTop = () => {
    console.log("hey");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  };

  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  const navigate = useNavigate();
  return (
    <HomeLayout>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="relative w-full"
      >
        <img
          className="w-full object-cover h-[500px] xl:h-auto left-0"
          src={Poster}
          alt="poster"
        />
        <div className="absolute bg-black text-white w-full h-full bg-opacity-[.5] top-0 left-0  flex flex-col items-center justify-center">
          <h1 className="font-bold text-[32px] xl:text-[52px] shadow-sm text-4xl text-center">
            All you need for your studies.
          </h1>
          <p className="font-semibold lg:text-2xl w-[70%] text-base line-clamp-3 shadow-sm text-center  lg:my-8 my-6">
            Get Academics and homework help direct from qualified Tutors
            available 24/7 access archives of worksheets and tutorials anytime.
          </p>
          <button
            onClick={() => navigate("/student/login")}
            className="flex items-center justify-center lg:px-6 lg:py-3 px-3 py-1.5 bg-[#F59C36] text-white font-bold  transform hover:scale-105 transition duration-300 ease-in-out rounded-[7px]"
          >
            Get Started
            <RightArrow />
          </button>
        </div>
      </motion.div>
      {/* <p className="text-center my-8 tracking-wider font-medium">
        Over 32K+ software businesses growing with WPenguino
      </p> */}

      {/* <div className="lg:relative bg-[#F69D3733] w-3/4 mx-auto lg:h-80 rounded-3xl flex flex-col items-center justify-center h-2/3 m-4 p-4">
        <img
          className="lg:w-64 h-72 lg:absolute relative w-full max-w-xs lg:right-2 top-2 bg-[#F59C36] m-4"
          src={Image1}
          alt="img"
        />
        <pre className=" lg:absolute relative w-full max-w-xs lg:left-1 text-center md:text-4xl text-xl  font-medium m-4">
          {`More than 13,000 teams
use our platform`}
        </pre>
        <Whatsapp className="hidden sm:block" />{" "}
      </div> */}

      {/* <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="w-full my-[60px] flex justify-center "
      >
        <div className="w-[85%] bg-[#F69D3733] rounded-[20px] flex flex-col sm:flex-row gap-[30px] sm:gap-0 items-start ">
          <div className="w-full sm:w-[60%] h-full relative p-[50px] flex flex-col items-center justify-center">
            <Whatsapp />
            <h2 className="relative text-[30px] w-full font-bold leading-[1.3em]">
              More than 13,000 teams
              <br />
              use our platform
            </h2>
          </div>
          <div className="w-full sm:w-[30%] flex justify-center">
            <img src={Image1} alt="sd" className="w-full h-full mt-[-50px] " />
          </div>
        </div>
      </motion.div> */}

      <h1 className="text-center  leading-[1.3em] tracking-wider text-[30px] sm:text-4xl font-bold mt-5">
        Explore Our Amazing Features 🔥
      </h1>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="w-full p-[30px] xl:px-[80px] flex flex-col sm:flex-row justify-center gap-[30px] xl:gap-[50px]"
      >
        {/* <FeatureCard
          heading="Lorem ipsum dolor sit"
          paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
          obcaecati? Odio sit reprehenderit qui facere."
          buttonText="Read more"
          isImg={true}
        />
        <FeatureCard
          heading="Lorem ipsum dolor sit"
          paragraph="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea,
          obcaecati? Odio sit reprehenderit qui facere."
          buttonText="Read more"
          isImg={true}
        /> */}
        <div className="w-full sm:w-[50%] xl:w-[30%] bg-[#f3f3f3] p-[40px] flex flex-col rounded-[7px] gap-[18px] items-center">
          <div className="w-[100px] h-[100px] rounded-[100px]">
            <img
              src={Mobile}
              alt="mobile"
              className="w-full h-full object-fill rounded-[100px]"
            />
          </div>
          <h3 className="text-center text-[20px] leading-[1.3em] font-semibold">
            SNAP. CONSULT. LEARN
          </h3>
          <p className="text-[#9f9f9f] font-medium text-center">
            Tutors available 24/7 to answer student's questions & doubts.
          </p>
        </div>
        <div className="w-full sm:w-[50%] xl:w-[30%] bg-[#f3f3f3] p-[40px] flex flex-col rounded-[7px] gap-[18px] items-center">
          <div className="w-[100px] h-[100px] rounded-[100px]">
            <img
              src={Idea}
              alt="mobile"
              className="w-full h-full object-fill rounded-[100px]"
            />
          </div>
          <h3 className="text-center text-[20px] leading-[1.3em] font-semibold">
            CUSTOMIZED LEARNING
          </h3>
          <p className="text-[#9f9f9f] font-medium text-center">
            Based on students needs and budget with option of online learning
            and F2F sessions.
          </p>
        </div>
      </motion.div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="w-full flex flex-col gap-[30px]"
      >
        <h3 className="text-center leading-[1.3em] tracking-wider text-3xl font-bold ">
          Pricing plans for Students
        </h3>
        {/* <p className="text-center  tracking-wider font-medium">
          *We help companies of all sizes
        </p> */}
        <div className="flex flex-col gap-[20px] xl:gap-0 xl:flex-row justify-center items-center px-[30px] sm:px-[50px]">
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[500px]">
            <HomePricingCard type="LEARNER" cost="29" />
          </div>
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[600px]">
            <HomePricingCard type="STUDENT" cost="38" />
          </div>
          <div className="w-full sm:w-[70%] xl:w-[33%] xl:h-[500px]">
            <HomePricingCard type="APPRENTICE" cost="56" />
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="p-[30px] sm:p-[50px] w-full"
      >
        <Slider />
      </motion.div>

      {/* <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="flex flex-col gap-6 justify-evenly items-center h-64 w-3/4 m-auto my-10 bg-[#F59C3645] rounded-2xl p-6"
      >
        <h2 className="lg:font-extrabold font-bold text-[#A15600] lg:text-5xl text-3xl leading-8 text-center ">
          Discover a better way to manage spending
        </h2>
        <button
          onClick={() => navigate("/student/login")}
          className="bg-[#F59C36] text-white rounded-lg p-3 hover:bg-[#F07100]"
        >
          Get Started Now
        </button>
      </motion.div> */}
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="w-[90%] flex flex-col gap-10 justify-evenly items-center  sm:w-3/4 m-auto my-10 bg-[#F5F3F5] rounded-2xl p-6"
      >
        <MessageIcon />
        <h3 className="font-bold text-center leading-6 ">
          Do you have more questions?
        </h3>
        <p className="font-medium text-center leading-[1.5em]  ">
          Should you have any queries, you can reach us via Whatsapp or
          <br />
          call us at 89382114
        </p>
        <Link
          className="w-full flex justify-center"
          to="mailto:officialtutormore@gmail.com"
        >
          <button className="sm:w-[80%] px-[30px] py-[12px] rounded-[7px] bg-[#f59c36] text-white">
            officialtutormore@gmail.com
          </button>
        </Link>
      </motion.div>

      <Footer />
    </HomeLayout>
  );
};

export default Home;
