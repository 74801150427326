import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  baseUrl,
  getRequest,
  postRequest,
} from "../../../../utils/apiServices";

export const fetchTeacherChats = createAsyncThunk(
  "message/fetchTeacherChats",
  async ({ userId }) => {
    console.log("user id", userId);
    try {
      const response = await getRequest(
        `${baseUrl}/get-teacher-chat/${userId}`
      );
      console.log(response.data.chat, "user id");
      return response.data.chat;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const fetchTeacherMessages = createAsyncThunk(
  "message/fetchTeacherMessages",
  async ({ chatid }) => {
    console.log(chatid);
    try {
      const response = await getRequest(
        `${baseUrl}/teacher/get-messages/${chatid}`,
        localStorage.getItem("teacherAuthToken")
      );
      console.log(response, "fetch message function");
      return response.data.data;
    } catch (error) {
      return error;
    }
  }
);

export const sendTeacherTextMessage = createAsyncThunk(
  "message/sendTeacherTextMessage",
  async ({ chatid, senderid, textMessage, attachment_url, attachment }) => {
    console.log("textmessage", chatid, senderid, textMessage);
    try {
      const response = await postRequest(
        `${baseUrl}/teacher/new-message`,
        {
          chatid: chatid,
          text: textMessage,
          senderid: senderid,
          attachment_url: attachment_url,
          attachment: attachment,
        },
        localStorage.getItem("teacherAuthToken")
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const createTeacherChat = createAsyncThunk(
  "message/createTeacherChat",
  async ({ studentId, teacherId }) => {
    console.log("hello");

    try {
      const response = await postRequest(`${baseUrl}/new-chat`, {
        studentId,
        teacherId,
      });

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const fetchTeacherData = createAsyncThunk(
  "message/fetchTeacherData",
  async () => {
    try {
      const response = await getRequest(
        `${baseUrl}/get-teacher`,
        localStorage.getItem("teacherAuthToken")
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchStudentData = createAsyncThunk(
  "message/fetchStudentData",
  async () => {
    try {
      const response = await getRequest(
        `${baseUrl}/get-student`,
        localStorage.getItem("studentAuthToken")
      );
      console.log(response, "dsadsa");
      return response.data.client;
    } catch (error) {
      console.error(error);
    }
  }
);
