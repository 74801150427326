import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  FaMagnifyingGlass,
  FaAnglesRight,
  FaEllipsis,
  FaRegImages,
  FaRegFileLines,
  FaX,
} from "react-icons/fa6";
import Header from "../common/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessages,
  fetchStudentChats,
  sendTextMessage,
} from "../state/redux/studentMessageRedux/studentThunkFunctions/studentThunkFunctions";
import moment from "moment";
import defaultImg from "../assets/profile1.jpg";
import {
  setCurrentChat,
  setNewMessage,
  setStudentMessages,
  setUserChats,
  setChatInactive,
} from "../state/redux/studentMessageRedux/studentMessageSlice";
import { io } from "socket.io-client";
import axios from "axios";
import { setLastMessage } from "../state/redux/studentMessageRedux/studentMessageSlice";
import Webcam from "react-webcam";
import Layout from "../studentPages/layout/layout";
import { setStudentButtons } from "../state/sidebarSlice";

function StudentChat() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStudentButtons("chat"));
  }, []);
  const studentMessageSlice = useSelector((state) => state.studentMessageSlice);
  const currentChat = useSelector(
    (state) => state.studentMessageSlice.currentChat
  );
  console.log(currentChat, "currentChat");
  const messages = studentMessageSlice.messages;
  const loadStudent = useSelector((state) => state.loadStudentSlice);
  console.log(loadStudent, "loadStudent");
  const socket = useSelector((state) => state.loadStudentSlice.socket);

  // const [socket, setSocket] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const currentConversation = useSelector(
    (state) => state.studentMessageSlice.currentChat
  );
  const [uploaded, setUploaded] = useState(false);
  const [inactive, setInactive] = useState();
  const [incomingMessage, setIncomingMessage] = useState(null);
  const [image, setImage] = useState(null);
  const handleProfileSubmit = (img) => {
    const formData = new FormData();
    console.log(img.file);
    formData.append("file", img?.file);
    // e.preventDefault();
    axios({
      method: "POST",
      url: `https://tutor-backend.hitoritech.com/api/v1/student/attachment-Upload`,
      headers: {
        Authorization: `Bearer {token}`,
      },
      data: formData,
    })
      .then((res) => {
        console.log(res, "DATA UPDATED");
        setAttachment(res.data.imgUrl);
        setUploaded(true);
      })
      .catch((err) => {
        console.log(err, "data update error");
      });
  };

  const handleChatIndex = (chatid) => {
    console.log(chatid, "chatid");
    const chatIndex = studentMessageSlice.userChats.findIndex(
      (chat) => chat.chatid === chatid
    );
    console.log(chatIndex);
    if (chatIndex > -1) {
      const updatedChats = [
        studentMessageSlice.userChats[chatIndex],
        ...studentMessageSlice.userChats.slice(0, chatIndex),
        ...studentMessageSlice.userChats.slice(chatIndex + 1),
      ];
      dispatch(setUserChats(updatedChats));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const blobUrl = URL.createObjectURL(file);
    console.log(blobUrl);
    const newImage = { file: file, url: blobUrl };
    setImage(newImage);
    console.log(newImage, "imageUrl");
    handleProfileSubmit(newImage);
  };

  const handleCamScreenShot = (base64Image) => {
    const formData = new FormData();
    // Convert Base64 image to Blob
    console.log(base64Image);
    formData.append("file", base64Image, "profile.jpg"); // You can give any filename
    axios({
      method: "POST",
      url: `https://tutor-backend.hitoritech.com/api/v1/student/attachment-Upload`,
      headers: {
        Authorization: `Bearer {token}`, // Make sure the token is correctly included
      },
      data: formData,
    })
      .then((res) => {
        console.log(res, "DATA UPDATED");
        setAttachment(res.data.imgUrl);
        setUploaded(true);
      })
      .catch((err) => {
        console.log(err, "data update error");
      });
  };

  useEffect(() => {
    console.log(loadStudent.user, "jok");
    if (loadStudent.user !== null) {
      console.log(loadStudent.user, "jok");
      dispatch(fetchStudentChats({ studentId: loadStudent?.user?.id }));
    }
  }, [loadStudent]);

  useEffect(() => {
    if (studentMessageSlice.userChats !== null) {
      dispatch(setCurrentChat(studentMessageSlice?.userChats[0]));
    }
  }, [studentMessageSlice.userChats]);

  // useEffect(() => {
  //   const newSocket = io("http://localhost:9000");
  //   newSocket.on("connect", () => {
  //     console.log("Connected to the server");
  //   });
  //   newSocket.on("connect_error", (error) => {
  //     console.error("Connection error:", error);
  //   });
  //   setSocket(newSocket);
  //   return () => {
  //     newSocket.disconnect();
  //   };
  // }, [loadStudent]);

  useEffect(() => {
    console.log(currentConversation, "currentConversation");
  }, [currentConversation]);

  // useEffect(() => {
  //   // if (loadStudent.user !== null) {
  //   if (socket === null) return;
  //   socket.emit("newStudent", loadStudent?.user?.id);
  //   // }
  // }, [socket]);

  useEffect(() => {
    if (socket === null) return;
    const handleNewMessage = (res) => {
      console.log(res.message, "koke");
      setIncomingMessage(res);
    };
    socket.on("resStudent", handleNewMessage);
    // console.log(resStudent);
    return () => {
      socket.off("resStudent", handleNewMessage);
    };
  }, [socket]);

  const handleProblemComplete = (problem) => {
    console.log(problem, "problem");
    dispatch(setChatInactive({ id: problem.chatid }));
  };

  useEffect(() => {
    if (socket === null) return;

    socket.on("completeProblem", handleProblemComplete);
    // console.log(resStudent);
    return () => {
      socket.off("completeProblem", handleProblemComplete);
    };
  }, [socket]);

  // const handleProblem = (res) => [console.log(res, "newProblem")];

  // useEffect(() => {
  //   socket.on("completeproblem", handleProblem);
  //   return () => {
  //     socket.off("completeproblem", handleProblem);
  //   };
  // }, [socket]);

  useEffect(() => {
    console.log(currentConversation, "res");
    console.log(
      incomingMessage,
      currentConversation?.chatid,
      incomingMessage?.message?.newMessage?.text,
      incomingMessage &&
        currentConversation?.chatid ===
          incomingMessage.message?.newMessage.chatid,
      "koke"
    );
    if (incomingMessage === null) return;

    if (
      incomingMessage &&
      currentConversation?.chatid === incomingMessage.message.newMessage.chatid
    ) {
      handleChatIndex(incomingMessage.message.newMessage.chatid);
      console.log(incomingMessage?.message?.newMessage, "res");
      dispatch(setNewMessage(incomingMessage?.message?.newMessage));
      dispatch(
        setLastMessage({
          id: incomingMessage?.message?.newMessage.chatid,
          message: incomingMessage?.message?.newMessage.text,
        })
      );
    }
    handleChatIndex(incomingMessage.message.newMessage.chatid);
    dispatch(
      setLastMessage({
        id: incomingMessage?.message?.newMessage.chatid,
        message: incomingMessage?.message?.newMessage.text,
      })
    );
  }, [incomingMessage]);

  const [resid, setResId] = useState();

  const [conversations, setConversations] = useState([
    {
      id: 1,
      name: "Md. Rofiq",
      messages: [
        {
          text: "Jam nonumy eirmod tempor invidunt ut labore et dolore magnaaliquyam erat consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat sed diam voluptua",
          isUser: false,
          senderName: "Md. Rofiq",
          date: new Date("2022-04-03T10:30:00").toLocaleString(),
        },
      ],
    },
    {
      id: 2,
      name: "Roshid Khan",
      messages: [
        {
          text: "Jam nonumy eirmod tempor invidunt ut labore et dolore magnaaliquyam erat consetetur sadipscing elitr sed diam nonumyeirmod tempor invidunt ut labore et dolore magnaaliquyam erat sed diam voluptua",
          isUser: false,
          senderName: "Roshid Khan",
          date: new Date("2022-04-03T11:30:00").toLocaleString(),
        },
      ],
    },
  ]);

  const [inputText, setInputText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);

  const fetchMessagesTop = async (chat) => {
    dispatch(setCurrentChat(chat));
    console.log(currentConversation, "currentConversation");
    console.log("Received chat object:", chat);
    setResId(chat.members.teacherid);
    console.log(currentConversation, "hello");
    // Assuming dispatch is to handle Redux or similar state management actions
    dispatch(fetchMessages({ chatid: chat.chatid }));
  };

  // useEffect(() => {
  //   if (studentMessageSlice.currentChat !== null) {
  //     dispatch(
  //       fetchMessages({
  //         chatid: studentMessageSlice.currentChat?.chatid,
  //       })
  //     );
  //   }
  // }, [incomingMessage]);

  const handleSendMessage = () => {
    const newMessage = {
      resid: resid,
      chatid: currentConversation.chatid,
      text: inputText,
      type: "student",
      createdAt: moment().toISOString(),
      attachment_url: attachment,
      attachment: uploaded,
    };

    console.log(newMessage, "hell");
    dispatch(
      setLastMessage({ id: currentConversation?.chatid, message: inputText })
    );
    dispatch(setStudentMessages([...messages, newMessage]));
    dispatch(
      sendTextMessage({
        chatid: currentConversation.chatid,
        senderid: loadStudent?.user?.id,
        textMessage: inputText,
        attachment_url: attachment,
        attachment: uploaded,
      })
    );
    handleChatIndex(currentConversation?.chatid);
    setUploaded(false);
    if (socket === null) return;
    socket.emit("StudentSendMessage", { newMessage });
    setInputText("");
  };
  const currentConversationName = conversations.find(
    (conv) => conv.id === currentConversation
  )?.name;

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchInput(searchValue);
    const filtered = conversations.filter((conv) =>
      conv.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredConversations(filtered);
  };

  const displayConversations =
    searchInput.length > 0 ? filteredConversations : conversations;

  const formatDate = (dateString) => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (messageDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return messageDate.toLocaleDateString(undefined, options);
    }
  };

  const scroll = useRef();
  useEffect(() => {
    scroll.current?.scrollIntoView({ behaviour: "smooth" });
  }, [messages]);

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
  };
  const [isCamActive, setIsCamActive] = useState(false);
  const [captured, setCaptured] = useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1], // Extract mime type (e.g., 'image/jpeg')
      bstr = atob(arr[1]), // Decode Base64 binary string
      n = bstr.length,
      u8arr = new Uint8Array(n); // Create a new array to hold the byte values

    // Populate the array with byte values
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Return a Blob created from the TypedArray
    return new Blob([u8arr], { type: mime });
  }

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURLtoBlob(imageSrc);
    setImgSrc(imageSrc); // Set base64 string to show image in UI
    setAttachment(blob); // Keep blob for upload
    handleCamScreenShot(blob); // Adjust handleCamScreenShot to accept blob
    setCaptured(true);
    setIsCamActive(false);
    setUploaded(false); // Change this to false initially
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (webcamRef.current && webcamRef.current.stream) {
  //       clearInterval(interval);
  //     }
  //   }, 100);
  //   return () => clearInterval(interval);
  // }, [webcamRef, capture]);
  console.log(attachment, "image");
  const [tabs, setTabs] = useState(1);
  const handleTabs = (index) => {
    setTabs(index || 1);
  };

  const [filterStatus, setFilterStatus] = useState("all");
  const filterChats = (status) => {
    switch (status) {
      case "active":
        return studentMessageSlice?.userChats?.filter(
          (chat) => chat.status === "active"
        );
      case "inactive":
        return studentMessageSlice?.userChats?.filter(
          (chat) => chat.status === "inactive"
        );
      case "all":
      default:
        return studentMessageSlice?.userChats;
    }
  };
  const filteredChats = filterChats(filterStatus);

  return (
    <Layout>
      <div className="flex h-full overflow-y-scroll hide-scrollbar flex-col sm:p-[25px] sm:hidden sm:flex-row justify-center w-full gap-[20px]">
        <div className="w-full flex-col sm:flex-row  h-full  bg-white  flex ">
          <div className="w-full sm:hidden flex justify-center">
            <button
              onClick={() => handleTabs(1)}
              className="font-semibold text-center px-[20px] py-[12px] border border-[#eaf2eb]">
              Chats
            </button>
            <button
              onClick={() => handleTabs(2)}
              className="font-semibold text-center px-[20px] py-[12px] border border-[#eaf2eb]">
              Messages
            </button>
          </div>
          {tabs === 1 ? (
            <div className="w-full sm:w-[40%] sm:h-full h-[600px]  m-b30">
              <div className="p-4 bg-white h-full overflow-y-scroll hide-scrollbar ">
                <div className="flex justify-between items-center">
                  <input
                    type="text"
                    placeholder="Input Here"
                    value={searchInput}
                    onChange={handleSearch}
                    style={{ padding: "10px 25px" }}
                    className="w-full border-none border-gray-400 rounded-3xl  mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                  />
                  <span
                    style={{
                      position: "relative",
                      right: "35px",
                      cursor: "pointer",
                      color: "#A0A0A0",
                    }}>
                    <FaMagnifyingGlass />
                  </span>
                </div>
                <div className="flex flex-col gap-[12px]">
                  {/* <h4 className="mt-4 text-[#8231D3] font-jost font-semibold text-xl leading-31.79 text-center">
                    <span className="border-b-2 border-[#8231D3]">
                      Private Chat
                    </span>
                  </h4> */}
                  <div className="w-full justify-center flex items-center text-[12px] font-semibold gap-[12px]">
                    <button
                      onClick={() => setFilterStatus("all")}
                      className={`py-[7px] px-[30px]  rounded-[20px] ${
                        filterStatus === "all"
                          ? "bg-[#f6a345] text-white"
                          : "bg-[#F4F5F7]"
                      }`}>
                      All
                    </button>
                    <button
                      onClick={() => setFilterStatus("active")}
                      className={`py-[7px] px-[30px]  rounded-[20px] ${
                        filterStatus === "active"
                          ? "bg-[#f6a345] text-white"
                          : "bg-[#F4F5F7]"
                      }`}>
                      Active
                    </button>
                    <button
                      onClick={() => setFilterStatus("inactive")}
                      className={`py-[7px] px-[30px]  rounded-[20px] ${
                        filterStatus === "inactive"
                          ? "bg-[#f6a345] text-white"
                          : "bg-[#F4F5F7]"
                      }`}>
                      Inactive
                    </button>
                  </div>
                </div>
                <ul className="job-list-container">
                  {filteredChats?.map((item) => (
                    <li key={item.index} className="mt-4">
                      <Link
                        to={"#"}
                        className={`conversation-item ${
                          item.chatid === currentConversation
                            ? "bg-blue-500 text-black"
                            : ""
                        }`}
                        onClick={() => {
                          fetchMessagesTop(item);
                          setTabs(2);
                        }}>
                        <div
                          className={`flex ${
                            item === currentConversation
                              ? "bg-[#f59c36] text-white"
                              : "bg-[#f7f7f7] text-gray-600"
                          }  w-full p-[10px]`}>
                          <div className="w-[20%] mr-[10px] flex justify-center items-center">
                            <div className="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-[50%]">
                              <img
                                src={
                                  item?.receptionDetails?.photo || defaultImg
                                }
                                alt=""
                                className="w-full h-full object-cover rounded-[50px]"
                              />
                            </div>
                          </div>
                          <h6 className=" font-jost font-semibold text-[14px] leading-21.68 text-left  ">
                            <h3 className="text-[14px] mb-[-2px]">
                              {item.receptionDetails.name}
                            </h3>
                            <p className="">{item.last_message}</p>
                          </h6>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
          {tabs === 2 ? (
            <div className="w-full relative sm:w-[70%] overflow-y-scroll hide-scrollbar h-full text-[12px] ">
              {uploaded ? (
                <div className="w-full h-full  absolute inset-0 bg-black bg-opacity-[0.5]  overflow-hidden flex justify-evenly p-[25px] flex-col items-center">
                  {captured === true ? (
                    <img
                      src={`https://tutor-backend.hitoritech.com/${attachment}`}
                      alt=""
                      className="w-[400px] h-[400px]"
                    />
                  ) : (
                    <img
                      src={`https://tutor-backend.hitoritech.com/${attachment}`}
                      alt=""
                      className="w-[400px] h-[400px]"
                    />
                  )}
                  <div className="message-input flex items-center mt-4 w-[80%]">
                    <input
                      type="text"
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      placeholder="Type your message..."
                      className="w-full border-none border-gray-400 rounded-3xl  bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                    />
                    <div
                      onClick={handleSendMessage}
                      className="bg-purple-800 text-white  rounded-full ml-2 w-12 h-10 flex items-center justify-center">
                      <FaAnglesRight className="text-lg cursor-pointer" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-4 bg-white h-full flex flex-col gap-[0px]">
                  <div className="flex justify-between items-center p-4">
                    <div>
                      <h2 className="text-lg font-jost font-semibold leading-21.68 text-left text-[#0A0A0A]">
                        {currentConversationName}
                      </h2>
                      <div className="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-[50%]">
                        <img
                          src={
                            currentConversationName?.receptionDetails?.photo ||
                            defaultImg
                          }
                          alt=""
                          className="w-full h-full object-cover rounded-[50px]"
                        />
                      </div>
                      <p className="text-[#0A0A0A] text-xs">Onlifgne</p>
                    </div>
                    <div>
                      <FaEllipsis />
                    </div>
                  </div>
                  {isCamActive === true ? (
                    <>
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                      <div className="flex justify-center items-center gap-[15px]">
                        <button onClick={capture}>Capture Photo</button>
                        <FaX
                          className="cursor-pointer"
                          onClick={() => setIsCamActive(false)}
                        />
                      </div>
                      {/* {imgSrc && <img src={imgSrc} alt="img" />} */}
                    </>
                  ) : (
                    <div className="mt-4 h-full  flex flex-col overflow-y-scroll">
                      {studentMessageSlice?.messages?.map((message, index) => (
                        <div
                          ref={scroll}
                          key={index}
                          className={`message ${
                            message.type === "teacher"
                              ? " text-white p-4"
                              : "  p-4"
                          }`}
                          style={{
                            display: " flex",
                            flexDirection: "column",
                          }}>
                          {index === 0 ||
                          formatDate(message?.createdAt) !==
                            formatDate(
                              studentMessageSlice.messages[index - 1]?.createdAt
                            ) ? (
                            <div className="mb-2 text-center text-sm font-bold text-[#747474]">
                              {formatDate(message?.createdAt)}
                            </div>
                          ) : null}

                          <div
                            className={`${
                              message?.type !== "teacher"
                                ? "p-[10px] self-end flex flex-col gap-[5px] bg-[#f4f5f7] text-black rounded-t-[10px] rounded-bl-[10px]"
                                : "p-[10px] self-start flex flex-col gap-[5px] bg-[#404040] text-black rounded-t-[10px] rounded-br-[10px]"
                            }`}>
                            <p
                              className={`font-jost font-medium text-15 leading-21.68 ${
                                message?.type !== "teacher"
                                  ? " text-[#0A0A0A]"
                                  : "text-[#FFFFFF]"
                              }`}>
                              {message?.attachment ? (
                                <p>
                                  <img
                                    src={`https://tutor-backend.hitoritech.com/${message?.attachment_url}`}
                                    alt=""
                                  />
                                </p>
                              ) : null}
                              <div className="flex flex-col gap-[4px]">
                                <p>{message?.text}</p>
                                <p className="text-[9px] text-right">
                                  {moment(message?.createdAt).format("h:mm a")}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {currentConversation?.status !== "inactive" ? (
                    <div className="message-input flex items-center mt-4">
                      <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        placeholder="Type your message..."
                        className="w-full border-none border-gray-400 rounded-3xl p-2 mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                      />

                      <div className="bg-[#F4F5F7]  text-[#747474]  rounded-full ml-2 w-12 h-10 flex items-center justify-center ">
                        <FaRegImages
                          onClick={() => {
                            setIsCamActive(true);
                          }}
                          className="text-lg cursor-pointer"
                        />
                      </div>
                      <div className="bg-[#F4F5F7] text-[#747474]  rounded-full ml-2 w-12 h-10  flex items-center justify-center ">
                        <div className="relative">
                          <label
                            title="Click to upload"
                            for="button2"
                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95">
                            <div className="relative">
                              <FaRegFileLines className="text-lg cursor-pointer" />
                            </div>
                          </label>
                          <input
                            type="file"
                            name="button2"
                            id="button2"
                            className="hidden"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                      <div
                        onClick={handleSendMessage}
                        className="bg-[#F4F5F7] text-[#A0A0A0] rounded-full ml-2 w-12 h-10 flex items-center justify-center">
                        <FaAnglesRight className="text-lg cursor-pointer" />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="h-full overflow-y-scroll hide-scrollbar hidden sm:flex flex-col bg-white">
        <div className="w-full mt-[20px] overflow-y-scroll hide-scrollbar h-full">
          <div className=" bg-white w-full h-full overflow-y-scroll hide-scrollbar">
            <div className=" xl:py-[20px] h-full overflow-y-scroll hide-scrollbar flex justify-center bg-[#f6a345]  ">
              <div className="xl:w-[90%] w-full overflow-y-scroll hide-scrollbar h-screen bg-white py-[0px] xl:rounded-[12px]">
                <div className="flex flex-col h-full overflow-y-scroll hide-scrollbar justify-center w-full sm:flex-row">
                  <div className="w-full sm:w-[40%] sm:h-full  m-b30">
                    <div className="p-4 bg-white h-full overflow-y-scroll hide-scrollbar ">
                      <div className="flex justify-between items-center">
                        <input
                          type="text"
                          placeholder="Input Here"
                          value={searchInput}
                          onChange={handleSearch}
                          style={{ padding: "10px 25px" }}
                          className="w-full border-none border-gray-400 rounded-3xl  mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                        />
                        <span
                          style={{
                            position: "relative",
                            right: "35px",
                            cursor: "pointer",
                            color: "#A0A0A0",
                          }}>
                          <FaMagnifyingGlass />
                        </span>
                      </div>
                      <div className="flex flex-col gap-[12px]">
                        {/* <h4 className="mt-4 text-[#8231D3] font-jost font-semibold text-xl leading-31.79 text-center">
                          <span className="border-b-2 border-[#8231D3]">
                            Private Chat
                          </span>
                        </h4> */}
                        <div className="w-full mt-[10px] justify-center flex items-center text-[12px] font-semibold gap-[12px]">
                          <button
                            onClick={() => setFilterStatus("all")}
                            className={`py-[7px] px-[30px]  rounded-[20px] ${
                              filterStatus === "all"
                                ? "bg-[#f6a345] text-white"
                                : "bg-[#F4F5F7]"
                            }`}>
                            All
                          </button>
                          <button
                            onClick={() => setFilterStatus("active")}
                            className={`py-[7px] px-[30px]  rounded-[20px] ${
                              filterStatus === "active"
                                ? "bg-[#f6a345] text-white"
                                : "bg-[#F4F5F7]"
                            }`}>
                            Active
                          </button>
                          <button
                            onClick={() => setFilterStatus("inactive")}
                            className={`py-[7px] px-[30px]  rounded-[20px] ${
                              filterStatus === "inactive"
                                ? "bg-[#f6a345] text-white"
                                : "bg-[#F4F5F7]"
                            }`}>
                            Inactive
                          </button>
                        </div>
                      </div>
                      <ul className="job-list-container">
                        {filteredChats?.map((item) => (
                          <li key={item.index} className="mt-4">
                            <Link
                              to={"#"}
                              className={`conversation-item ${
                                item.chatid === currentConversation
                                  ? "bg-blue-500 text-black"
                                  : ""
                              }`}
                              onClick={() => {
                                fetchMessagesTop(item);
                              }}>
                              <div
                                className={`flex ${
                                  item === currentConversation
                                    ? "bg-[#f59c36] text-white"
                                    : "bg-[#f7f7f7] text-gray-600"
                                }  w-full p-[10px]`}>
                                <div className="w-[20%] mr-[10px] flex justify-center items-center">
                                  <div className="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-[50%]">
                                    <img
                                      src={
                                        defaultImg ||
                                        item?.receptionDetails?.photo
                                      }
                                      alt=""
                                      className="w-full h-full object-cover rounded-[50px]"
                                    />
                                  </div>
                                </div>
                                <h6 className=" font-jost font-semibold text-[14px] leading-21.68 text-left  ">
                                  <h3 className="text-[14px] mb-[-2px]">
                                    {item.receptionDetails.name}
                                  </h3>
                                  <p>{item.last_message}</p>
                                </h6>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="w-full relative sm:w-[70%] overflow-y-scroll hide-scrollbar h-full text-[14px]">
                    {uploaded ? (
                      <div className="w-full h-full  absolute inset-0 bg-black bg-opacity-[0.5]  overflow-hidden flex justify-evenly p-[25px] flex-col items-center">
                        {captured === true ? (
                          <img
                            src={`https://tutor-backend.hitoritech.com/${attachment}`}
                            alt=""
                            className="w-[400px] h-[400px]"
                          />
                        ) : (
                          <img
                            src={`https://tutor-backend.hitoritech.com/${attachment}`}
                            alt=""
                            className="w-[400px] h-[400px]"
                          />
                        )}
                        <div className="message-input flex items-center mt-4 w-[80%]">
                          <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Type your message..."
                            className="w-full border-none border-gray-400 rounded-3xl  bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                          />
                          <div
                            onClick={handleSendMessage}
                            className="bg-purple-800 text-white  rounded-full ml-2 w-12 h-10 flex items-center justify-center">
                            <FaAnglesRight className="text-lg cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" bg-white h-full flex flex-col gap-[0px] ">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3 bg-[white] w-full p-2">
                            <img
                              src={
                                defaultImg ||
                                currentConversation?.receptionDetails?.photo
                              }
                              alt=""
                              className="w-[50px] h-full object-cover rounded-[50px]"
                            />
                            <h2 className="text-lg font-jost font-semibold leading-21.68 text-left text-[#0A0A0A]">
                              {currentConversation?.receptionDetails.name}
                            </h2>
                            {/* <p className="text-[#0A0A0A] text-xs">Online</p> */}
                          </div>
                        </div>
                        {isCamActive === true ? (
                          <>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                            />
                            <div className="flex justify-center items-center gap-[15px]">
                              <button onClick={capture}>Capture Photo</button>
                              <FaX
                                className="cursor-pointer"
                                onClick={() => setIsCamActive(false)}
                              />
                            </div>
                            {/* {imgSrc && <img src={imgSrc} alt="img" />} */}
                          </>
                        ) : (
                          <div className="h-full  flex flex-col overflow-y-scroll bg-opacity-15 bg-[#f59c37] border border-gray-100">
                            {studentMessageSlice?.messages?.map(
                              (message, index) => (
                                <div
                                  ref={scroll}
                                  key={index}
                                  className={`message ${
                                    message.type === "teacher"
                                      ? " text-white p-4"
                                      : "  p-4"
                                  }`}
                                  style={{
                                    display: " flex",
                                    flexDirection: "column",
                                  }}>
                                  {index === 0 ||
                                  formatDate(message?.createdAt) !==
                                    formatDate(
                                      studentMessageSlice.messages[index - 1]
                                        ?.createdAt
                                    ) ? (
                                    <div className="mb-2 text-center text-sm font-bold text-[#747474]">
                                      {formatDate(message?.createdAt)}
                                    </div>
                                  ) : null}
                                  {/* <div className="">
                            <div className=" ">
                              <div>
                                {message.isUser ? (
                                  <div className="flex justify-between items-center">
                                    <span className=" font-jost font-semibold text-15 leading-21.68  text-[#0A0A0A] ">
                                      You
                                    </span>
                                    <div className="text-xs text-gray-500 ">
                                      {formatDate(message.date)}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center ">
                                    <img
                                      src={message.profileImage}
                                      alt="Profile"
                                      className="w-6 h-6 rounded-full mr-2"
                                    />
                                    <div className="flex justify-start items-center">
                                      <span className="font-jost font-semibold text-15 leading-21.68 text-left text-[#0A0A0A]">
                                        {message.senderName}
                                      </span>
                                      <div className="text-xs text-gray-500 ml-2">
                                        {formatDate(message.date)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div> */}
                                  <div
                                    // className={`mb-2 ${
                                    //   message.type !== "teacher"
                                    //     ? "bg-[#F4F5F7] text-white"
                                    //     : "bg-[#404040] text-black"
                                    // }  p-4 mt-2 w-auto rounded-r-lg rounded-b-lg ml-2`}

                                    className={`${
                                      message?.type !== "teacher"
                                        ? "p-[10px] self-end flex flex-col gap-[5px] bg-[#f4f5f7] text-black rounded-t-[10px] rounded-bl-[10px]"
                                        : "p-[10px] self-start flex flex-col gap-[5px] bg-[#404040] text-black rounded-t-[10px] rounded-br-[10px]"
                                    }`}>
                                    <p
                                      className={`font-jost font-medium text-15 leading-21.68 ${
                                        message?.type !== "teacher"
                                          ? " text-[#0A0A0A]"
                                          : "text-[#FFFFFF]"
                                      }`}>
                                      {message?.attachment ? (
                                        <p>
                                          <img
                                            src={`https://tutor-backend.hitoritech.com/${message?.attachment_url}`}
                                            alt=""
                                          />
                                        </p>
                                      ) : null}
                                      <div className="flex flex-col gap-[4px]">
                                        <p>{message?.text}</p>
                                        <p className="text-[9px] text-right">
                                          {moment(message?.createdAt).format(
                                            "h:mm a"
                                          )}
                                        </p>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                        {currentConversation?.status !== "inactive" ? (
                          <div className="message-input flex items-center mt-4 m-2">
                            <input
                              type="text"
                              value={inputText}
                              onChange={(e) => setInputText(e.target.value)}
                              placeholder="Type your message..."
                              className="w-full border-none border-gray-400 rounded-3xl p-2 mb-2 bg-[#F4F5F7] text-[#A0A0A0] focus:outline-none"
                            />

                            <div className="rounded-full ml-2 w-12 h-10 flex items-center justify-center  text-white bg-[#f59c37]">
                              <FaRegImages
                                onClick={() => {
                                  setIsCamActive(true);
                                }}
                                className="text-lg cursor-pointer"
                              />
                            </div>
                            <div className="bg-[#F4F5F7] text-[#747474]  rounded-full ml-2 w-12 h-10  flex items-center justify-center ">
                              <div className="relative">
                                <label
                                  title="Click to upload"
                                  for="button2"
                                  className="rounded-full ml-2 w-12 h-10 flex items-center justify-center  text-white bg-[#f59c37]">
                                  <div className="relative">
                                    <FaRegFileLines className="text-lg cursor-pointer" />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  name="button2"
                                  id="button2"
                                  className="hidden"
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>
                            <div
                              onClick={handleSendMessage}
                              className=" text-white rounded-full ml-2 w-12 h-10 flex items-center justify-center bg-[#f59c37]">
                              <FaAnglesRight className="text-lg cursor-pointer" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default StudentChat;
