import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialVimeo } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import Logo from "../assets/header/header.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const Footer = () => {
  const [newsLetter, setNewsLetter] = useState("");
  const handleChange = (e) => {
    setNewsLetter(e.target.value);
  };
  return (
    <footer className="bg-[#F59C3633] text-gray-800 py-8 px-4">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row justify-center gap-12 items-center h-1/2 mb-6 text-white lg:mb-0 bg-[#F59C36] p-12">
        <h2 className="text-2xl font-bold mr-10">Subscribe Newsletters</h2>

        <div className="w-full xl:w-[50%] flex flex-col sm:flex-row justify-center  gap-[7px]">
          <input
            type="text"
            name="newsLetter"
            id="newsLetter"
            value={newsLetter}
            onChange={handleChange}
            placeholder="Enter your email"
            className="w-full sm:w-[70%] xl:w-[60%] p-[10px] bg-white  text-black  rounded-[10px] outline-none text-[14px]"
          />
          <button className=" bg-[#fdebd7] p-[7px] cursor-pointer  font-semibold text-[#f59c36] rounded-[7px]">
            SUBSCRIBE NOW
          </button>
        </div>
      </div>
      <div className="flex mb-4 lg:mb-0 justify-between lg:m-2 lg:p-2 m-1 p-1 ">
        <div className="flex flex-col md:flex-row">
          <Link to={"/"} className="mx-2">
            Home
          </Link>
          <Link to={"/about"} className="mx-2">
            About us
          </Link>
          <Link to={"/pricing"} className="mx-2">
            Pricing
          </Link>
          <Link to={"/schedule"} className="mx-2">
            Schedule
          </Link>
          <Link to={"/student/login"} className="mx-2">
            Login As Student
          </Link>
          <Link to={"/teacher/login"} className="mx-2">
            Login As Teacher
          </Link>
        </div>
        <div className="flex mb-4 lg:mb-0 md:gap-4 gap-2">
          <TiSocialFacebook size={24} />
          <TiSocialTwitter size={24} />
          <TiSocialVimeo size={24} />
          <TiSocialYoutube size={24} />
        </div>
      </div>
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row justify-between items-center border-t-[1px] border-orange-600 mt-2 pt-2">
        <p className="text-sm mb-2 lg:mb-0">
          © 2019 Lift Media. All rights reserved.
        </p>
        <div>
          <img
            className="xl:w-28 xl:h-16 md:ml-6 w-36 h-20 my-2 ml-4"
            src={Logo}
            alt="Logo"
          />
        </div>
        <div className="flex mb-2 lg:mb-0">
          <a href="#" className="text-sm mx-2">
            Terms of Service
          </a>
          <a href="#" className="text-sm mx-2">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
