import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  studentLoginValues: {
    email: "",
    password: "",
  },
  studentRegisterValues: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  },
};

const studentAuthSlice = createSlice({
  name: "studentAuthSlice",
  initialState,
  reducers: {
    setStudentLoginValues: (state, action) => {
      state.studentLoginValues = action.payload;
    },
    setStudentRegisterValues: (state, action) => {
      state.studentRegisterValues = action.payload;
    },
  },
});

export const { setStudentLoginValues, setStudentRegisterValues } =
  studentAuthSlice.actions;

export default studentAuthSlice.reducer;
