import React, { useState } from "react";
import HomeLayout from "./layout/HomeLayout";
import PricingCard from "./components/PricingCard";
import image5 from "../assets/home/image5.png";
import image6 from "../assets/home/image6.png";
import ReviewCard from "./components/ReviewCard";
import { FaPlus, FaUserGroup } from "react-icons/fa6";
import { GrGroup } from "react-icons/gr";
import PositiveReview from "./svg/PositiveReview";
import Accordion from "./components/Accordion";
import Footer from "../common/Footer";
import Img7 from "../assets/about/img8.png";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/header/Tutormore logo A.png";
import { MdCheckCircleOutline } from "react-icons/md";
const Pricing = () => {
  const includedFeatures = [
    "Private forum access",
    "Member resources",
    "Entry to annual conference",
    "Official member t-shirt",
  ];
  const [tabs, setTabs] = useState(null);
  const handleTabs = (index) => {
    setTabs(index === tabs ? null : index);
  };
  const navigate = useNavigate();
  const Animation = {
    hidden: {
      opacity: 0,
      y: 75,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };
  return (
    <HomeLayout>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="text-center my-[50px] p-4"
      >
        <h2 className=" text-3xl font-semibold top-6">
          Pricing plans for Students
        </h2>
        {/* <p className="mt-4 tracking-wider font-medium">
          *We help companies of all sizes
        </p> */}
      </motion.div>
      <div className="flex justify-center ">
        {/* <PricingCard type="BASIC" cost="20" />
        <PricingCard type="ENTERPRISE" cost="50" />
        <PricingCard type="STARTER" cost="10" /> */}
        <div className="w-full flex-col  gap-[20px] xl:gap-[0px] xl:flex-row px-[30px] sm:px-[50px] xl:px-[100px] mb-[20px] flex justify-center">
          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full xl:w-[33%] border rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]"
          >
            <div className="flex items-center justify-between">
              <img src={Img7} alt="check" />
              <p className="font-medium">LEARNER</p>
            </div>
            <h2 className="text-[30px] font-semibold">SGD$29.00 /month</h2>
            <ul className="list-disc px-[15px] flex flex-col gap-[7px]">
              <li>Up to 35 questions per month</li>
              <li>Interact daily with Tutors and get your questions solved</li>
              <li>Primary School (All Levels) P1 to P6</li>
              <li>Subjects (English, Mathematics and Science)</li>
              <li>CHAS Card required to subscribe for this category</li>
            </ul>
            <button
              onClick={() => navigate("/student/pricing")}
              className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
            >
              SIGN UP NOW
            </button>
          </motion.div>
          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full xl:w-[33%] border-2 xl:my-[-30px] rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]"
          >
            <div className="flex items-center justify-between">
              <img src={Img7} alt="check" />
              <p className="font-medium">STUDENT</p>
            </div>
            <h2 className="text-[30px] font-semibold">SGD$38.00 /month</h2>
            <ul className="list-disc flex flex-col gap-[7px] px-[15px]">
              <li>Up to 50 questions per month</li>
              <li>Interact daily with Tutors and get your questions solved</li>
              <li>Primary School (All Levels) P1 to P6</li>
              <li>Subjects (English, Mathematics and Science)</li>
            </ul>
            <button
              onClick={() => navigate("/student/pricing")}
              className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
            >
              SIGN UP NOW
            </button>
          </motion.div>

          <motion.div
            variants={Animation}
            initial="hidden"
            whileInView={"visible"}
            className="w-full xl:w-[33%] border rounded-[7px] border-[#f59c36] px-[30px] py-[60px] flex flex-col gap-[30px]"
          >
            <div className="flex items-center justify-between">
              <img src={Img7} alt="check" />
              <p>APPRENTICE</p>
            </div>
            <h2 className="text-[30px] font-semibold">SGD$56.00 /month</h2>
            <ul className="list-disc px-[15px] flex flex-col gap-[7px]">
              <li>Up to 80 questions per month</li>
              <li>Interact daily with Tutors and get your questions solved</li>
              <li>Primary School (All Levels) P1 to P6</li>
              <li>Subjects (English, Mathematics and Science)</li>
            </ul>
            <button
              onClick={() => navigate("/student/pricing")}
              className="bg-[#f59c36] p-[15px] text-white rounded-[12px]"
            >
              SIGN UP NOW
            </button>
          </motion.div>
        </div>
      </div>
      <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="relative my-16 h-[300px]"
      >



        <div className="w-full h-[400px]  bg-[#f59c36] flex items-center ">
          <h1 className="text-[60px] font-semibold text-center  text-white top-20 w-full">
            Why choose Tutormore 🎖️
          </h1>

        </div>
        {/* s */}
      </motion.div>
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div class="lg:w-1/4 mt-48 hidden lg:block">
          <div class="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
            {/* <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start -mt-px">
              Fingerstache disrupt
            </p> */}
            <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">
              Schedule/Timing
            </p>
            <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">
              Location
            </p>
            <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">
              Personalised Learning
            </p>
            <p class="bg-gray-100 text-gray-900 h-12 text-left px-4 flex items-center justify-start">
              Academic help width student's question
            </p>
            <p class="text-gray-900 h-12 text-center px-4 flex items-center justify-start">
              Pricing
            </p>
            <p class="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start">
              Base Product Cost
            </p>
          </div>
        </div>
        <div class="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
          <div class="lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-4 border-orange-300 lg:border-none rounded-lg lg:rounded-none">
            <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
              <h2 class="text-5xl text-gray-900 font-medium leading-none mb-4 mt-2">
                <img src={logo} alt="" />
              </h2>
            </div>
            {/* <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p> */}
            <p class="text-gray-600 text-center h-12 flex items-center justify-center border-t border-gray-300">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0 ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
            <p class="h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
            <p class="text-gray-600 text-center h-12 flex items-center justify-center">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <span class="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  class="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
            </p>
          </div>
          <div class="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg  relative">
            <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
              <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                Tuition Classes
              </h2>
            </div>
            {/* <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p> */}
            <p class="text-gray-600 text-center h-12 flex items-center justify-center border-t border-gray-300">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
          </div>
          <div class="lg:w-1/3 w-full lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
            <div class="px-2 text-center h-48 flex flex-col items-center justify-center">
              <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                Private Tutor
              </h2>
            </div>
            {/* <p class="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p> */}
            <p class="text-gray-600 text-center h-12 flex items-center justify-center border-t border-gray-300">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
            <p class="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.2"
                class="w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
              >
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </p>
          </div>
        </div>
      </div>
      {/* <motion.div
        variants={Animation}
        initial="hidden"
        whileInView={"visible"}
        className="p-[30px] bg-white sm:p-[50px] xl:p-[80px] mt-[250px] sm:mt-[350px] xl:mt-[0px]">
        <h1 className=" text-4xl font-semibold text-center   pt-4 mb-4 pb-4 tracking-wide">
          Frequently Asked Questions
        </h1>
        <div className="w-full flex flex-col gap-[20px] z-[50]">
          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(1)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">
                How it Will take impact for food & restaurant business.
              </h3>
              <FaPlus />
            </div>
            {tabs === 1 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(2)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">Financial Services</h3>
              <FaPlus />
            </div>
            {tabs === 2 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(3)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">
                Do you offer a 30 days money-back guarantee?
              </h3>
              <FaPlus />
            </div>
            {tabs === 3 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(4)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">
                Do I need a credit card to Sign up?
              </h3>
              <FaPlus />
            </div>
            {tabs === 4 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(5)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">
                Why does your business need a ChatBot?
              </h3>
              <FaPlus />
            </div>
            {tabs === 5 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-[0px]">
            <div
              onClick={() => handleTabs(6)}
              className="flex justify-between p-[20px] border border-gray-300 font-semibold items-center rounded-[12px] cursor-pointer">
              <h3 className="font-semibold">How does I change My Tutor</h3>
              <FaPlus />
            </div>
            {tabs === 6 ? (
              <div className="p-[20px] border border-gray-300 rounded-[12px]">
                <p>
                  A chatbot is always available to serve your customer 24/7. A
                  chatbot can save you a lot of time and money by automating
                  repetitive tasks like qualifying leads.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div> */}
      <Footer />
    </HomeLayout>
  );
};

export default Pricing;
