import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  isMessageLoading: false,
  messageError: null,
  userChats: null,
  isUserChatsLoading: false,
  userChatsError: null,
  chatId: null,
  isChatIdLoading: false,
  chatIdError: false,
  sendTextMessageError: null,
  newMessage: null,
  currentChat: null,
};
const adminTeacherMessageSlice = createSlice({
  name: "adminTeacherMessageSlice",
  initialState,
  reducers: {
    setUserChats: (state, action) => {
      state.userChats = action.payload;
    },
    setIsUserChatsLoading: (state, action) => {
      state.isUserChatsLoading = action.payload;
    },
    setUserChatsError: (state, action) => {
      state.userChatsError = action.payload;
    },
    setStudentMessages: (state, action) => {
      state.messages = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setIsMessageLoading: (state, action) => {
      state.isMessageLoading = action.payload;
    },
    setMessageError: (state, action) => {
      state.messageError = action.payload;
    },
    setSendTextMessageError: (state, action) => {
      state.sendTextMessageError = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
      state.messages = [...state.messages, action.payload];
    },
    setTextMessage: (state, action) => {
      state.textMessage = action.payload;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    setLastMessage: (state, action) => {
      const { id, message } = action.payload;
      const chatIndex = state.userChats.findIndex((chat) => chat.chatid === id);
      console.log(state.userChats);
      state.userChats[chatIndex].last_message = message;
    },
  },
});

export const {
  setUserChats,
  setIsUserChatsLoading,
  setUserChatsError,
  setStudentMessages,
  setChatId,
  setIsMessageLoading,
  setMessageError,
  setSendTextMessageError,
  setNewMessage,
  setTextMessage,
  setLastMessage,
  setCurrentChat,
} = adminTeacherMessageSlice.actions;
export default adminTeacherMessageSlice.reducer;
