import React, { useState, useEffect } from "react";
import Logo from "../assets/header/Tutormore logo A.png";
import { MdMenu } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";
const Header = () => {
  const [activeTab, setActiveTab] = useState("HOME");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveTab("HOME");
        break;
      case "/about":
        setActiveTab("ABOUT");
        break;
      case "/pricing":
        setActiveTab("PRICING");
        break;
      case "/schedule":
        setActiveTab("SCHEDULE");
        break;
      default:
        setActiveTab("HOME"); // Default case if none of the paths match
    }
  }, [location]);
  // Function to handle tab click
  const handleTabClick = (tab) => {
    // setActiveTab(tab);
    setIsMenuOpen(false);
  };
  const navigate = useNavigate();
  return (
    <div className="flex font-lato bg-white w-full h-24  top-14px z-20 py-15px px-30px text-14px justify-between items-center gap-30px text-lg">
      <div>
        <img
          className="xl:w-28 xl:h-16 md:ml-6 w-25 h-14 my-2 ml-4 object-contain"
          src={Logo}
          alt="Logo"
        />
      </div>

      {/* Navigation Items for Larger Screens */}
      <div className="hidden xl:flex">
        <ul className="flex flex-nowrap lg:gap-24 gap-2 text-[#2A1E17] font-semibold">
          <Link to={"/"}>
            <li
              className={` transition duration-300 ease-in-out cursor-pointer ${
                activeTab === "HOME" ? "border-b-4 border-[#F59C36]" : ""
              }`}
              onClick={() => handleTabClick("HOME")}
            >
              HOME
            </li>
          </Link>
          <Link to={"/about"}>
            <li
              className={`transition duration-300 ease-in-out cursor-pointer ${
                activeTab === "ABOUT" ? "border-b-4 border-[#F59C36]" : ""
              }`}
              onClick={() => handleTabClick("ABOUT")}
            >
              ABOUT
            </li>
          </Link>
          <Link to={"/pricing"}>
            <li
              className={`transition duration-300 ease-in-out cursor-pointer ${
                activeTab === "PRICING" ? "border-b-4 border-[#F59C36]" : ""
              }`}
              onClick={() => handleTabClick("PRICING")}
            >
              PRICING
            </li>
          </Link>
          <Link to={"/schedule"}>
            <li
              className={`transition duration-300 ease-in-out cursor-pointer ${
                activeTab === "SCHEDULE" ? "border-b-4 border-[#F59C36]" : ""
              }`}
              onClick={() => handleTabClick("SCHEDULE")}
            >
              SCHEDULE
            </li>
          </Link>
        </ul>
      </div>

      {/* Login and Register Buttons */}
      <div className="text-[#F59C36] flex items-center flex-nowrap lg:gap-8 gap-6 mr-4 font-semibold ">
        <div className="hidden xl:flex items-center gap-[12px]">
          <Link to="/student/login">
            <button className="lg:px-8 lg:py-3 px-2 py-1">LOGIN</button>
          </Link>
          <Link to="/student/register">
            <button className="lg:px-8 lg:py-3 px-2 py-1 bg-[#F59C3633] rounded-md">
              REGISTER
            </button>
          </Link>
        </div>
        {/* Hamburger Menu for Mobile */}
        <div className="xl:hidden">
          <button
            className="text-[#F59C36]"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <MdMenu className="w-8 h-8" />
          </button>
          {isMenuOpen && (
            <div className="flex flex-col gap-[20px] items-center absolute h-full top-0 w-full sm:w-[50%] p-[25px] right-0 bg-white shadow-lg z-[99]">
              <div className="w-full flex justify-end">
                <button
                  className="text-[#F59C36]"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <FaX className="w-8 h-8" />
                </button>
              </div>
              <div>
                <img
                  className="xl:w-28 xl:h-16 md:ml-6 w-25 h-14 my-2 ml-4 object-contain"
                  src={Logo}
                  alt="Logo"
                />
              </div>
              <div className="w-full flex flex-col gap-[20px]">
                <button
                  className={`transition duration-300 ease-in-out cursor-pointer ${
                    activeTab === "HOME" ? "border-b-3 border-[#F59C36]" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("HOME");
                    navigate("/");
                  }}
                >
                  HOME
                </button>
                <button
                  className={`transition duration-300 ease-in-out cursor-pointer ${
                    activeTab === "ABOUT" ? "border-b-3 border-[#F59C36]" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("ABOUT");
                    navigate("/about");
                  }}
                >
                  ABOUT
                </button>
                <button
                  className={`transition duration-300 ease-in-out cursor-pointer ${
                    activeTab === "PRICING" ? "border-b-3 border-[#F59C36]" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("PRICING");
                    navigate("/pricing");
                  }}
                >
                  PRICING
                </button>
                <button
                  className={`transition duration-300 ease-in-out cursor-pointer ${
                    activeTab === "SCHEDULE"
                      ? "border-b-3 border-[#F59C36]"
                      : ""
                  }`}
                  onClick={() => {
                    handleTabClick("SCHEDULE");
                    navigate("/schedule");
                  }}
                >
                  SCHEDULE
                </button>
                <div className="xl:hidden w-full flex flex-col items-center gap-[20px]">
                  <Link className="w-full" to="/student/login">
                    <button className="w-full ">LOGIN</button>
                  </Link>
                  <Link className="w-full" to="/student/register">
                    <button className="w-full lg:px-8 lg:py-3 px-2 py-1 bg-[#F59C3633] rounded-md">
                      REGISTER
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
