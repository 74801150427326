import React from "react";
import Img from "../../assets/header/header.png";
import {
  FaAddressBook,
  FaArrowRightFromBracket,
  FaHatWizard,
  FaPenFancy,
  FaRegPaperPlane,
  FaStar,
  FaUserGroup,
  FaWrench,
} from "react-icons/fa6";
import img from "../../assets/admin/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setTeacherButtons } from "../../state/sidebarSlice";
const Sidebar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("teacherToken");
    navigate("/teacher/login");
  };
  const teacherButtons = useSelector(
    (state) => state.sidebarSlice.teacherButtons
  );
  const dispatch = useDispatch();
  return (
    <div className="p-[20px] h-full flex flex-col items-center justify-between gap-[25px]">
      <div className="flex w-full flex-col gap-[20px] items-center">
        <img src={Img} alt="profile" className="w-[80%]" />
        <div className="w-full flex flex-col gap-[12px] text-[12px]">
          <Link
            onClick={() => dispatch(setTeacherButtons("dashboard"))}
            to="/teacher"
            className={`w-full p-[12px] ${
              teacherButtons === "dashboard"
                ? "bg-[#f59c36] text-white"
                : "bg-transparent"
            } hover:bg-[#f59c36] hover:text-white rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center transition-all`}
          >
            <FaUserGroup className="text-forthColor" /> Tutor Dashboard
          </Link>
          <Link
            to="/teacher/chat"
            onClick={() => dispatch(setTeacherButtons("chat"))}
            className={`w-full p-[12px] ${
              teacherButtons === "chat"
                ? "bg-[#f59c36] text-white"
                : "bg-transparent"
            } hover:bg-[#f59c36] hover:text-white rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center transition-all`}
          >
            <FaRegPaperPlane className="text-forthColor" /> Tutor Direct
          </Link>

          <Link
            to={"/teacher/account"}
            onClick={() => dispatch(setTeacherButtons("account"))}
            className={`w-full p-[12px] ${
              teacherButtons === "account"
                ? "bg-[#f59c36] text-white"
                : "bg-transparent"
            } hover:bg-[#f59c36] hover:text-white rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center transition-all`}
          >
            <FaAddressBook className="text-forthColor" /> Account
          </Link>

          <Link
            to={"/teacher/problem-feed"}
            onClick={() => dispatch(setTeacherButtons("problemFeed"))}
            className={`w-full p-[12px] ${
              teacherButtons === "problemFeed"
                ? "bg-[#f59c36] text-white"
                : "bg-transparent"
            } hover:bg-[#f59c36] hover:text-white rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center transition-all`}
          >
            <FaQuestionCircle className="text-forthColor" /> Problem Feed
          </Link>
          {/* <button className="p-[12px] hover:bg-[#f59c36] hover:text-white rounded-[7px] font-semibold flex gap-[7px]  bg-secondaryColor items-center">
            <FaWrench className="text-forthColor" />
            Settings
          </button> */}
        </div>
      </div>
      <div className=" w-full flex flex-col gap-[15px] text-[14px]">
        {/* <div className="w-full flex p-[15px] flex-col items-center rounded-[12px] gap-[12px] bg-[#f59c36] text-white">
          <img src={img} alt="logo" className="w-[50px]" />
          <h3 className="text-[20px] font-medium">Tutormore</h3>
          <p className="text-center leading-[1.3em]">
            Get access to all
            <br />
            features on tetumbas
          </p>
          <button className="px-[15px] py-[7px] rounded-[7px] bg-white font-medium text-[#f59c36]">
            Get Pro
          </button>
        </div>{" "} */}
        <button
          onClick={handleLogout}
          className="w-full text-[12px] hover:bg-[#f59c36] hover:text-white p-[12px] rounded-[7px] font-semibold flex gap-[7px]  transition-all bg-secondaryColor items-center"
        >
          <FaArrowRightFromBracket className="text-forthColor" /> Log Out
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
